body { // required for footer to stick to bottom of pages if page isn't tall enough to span the browser height
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	// admin bar at wordpress native breakpoints
	@media screen and (max-width: 782.98px) {
		&.admin-bar {
			min-height: calc( 100vh - 46px );
		}
	}
	@media screen and (min-width: 783px) {
		&.admin-bar {
			min-height: calc( 100vh - 32px );
		}
	}
}


footer {
	border-top: 1px solid rgba($light-bootstrap-default, 0.5);

	.footer-menu {
		.footer-column-label {
			font-family: $font-family-headline;
			font-size: 16px;
			line-height: 33px;
			letter-spacing: 9px;
			outline: 0;
		}
		.footer-menu-group {
			line-height: 1.25;

			a {
				color: $black;
				font-family: $font-family-sans-serif;
				font-size: 14px;
				// line-height: 23px;
				display: inline-block;
				margin-bottom: 0.3rem;
			}
		}
		@include media-breakpoint-down(xs) {
			.footer-column-label {
				border-bottom-color: rgba( $columbian-light, 0.5 ) !important;

				&:after {
					content: '';
					display: inline-block;
					border: solid rgba( $black, 0.2 );
					border-width: 0 3px 3px 0;
					transform: rotate(45deg);
					padding: 3px;
					margin-left: 0.4rem;
					vertical-align: 13%;
					transition: border-color 0.5s ease-in-out;
				}
			}
			.footer-menu-group {
				visibility: hidden;
				opacity: 0;
				transition: 0.5s ease-in-out;
				max-height: 0;
				overflow: hidden;

				a {
					font-size: 100%;
					line-height: 30px;

					&:last-child {
						display: inline-block;
						margin-bottom: 1.75rem;
					}
				}
			}
			.footer-menu-column:hover {
				& > .footer-column-label:after {
					border-color: rgba( $black, 0.5 );
				}
				& > .footer-menu-group {
					visibility: visible;
					opacity: 1;
					max-height: 1000px;
					// margin-bottom: 1rem;
				}
			}
		}
	}
	.footer-logo {
		margin-bottom: 2rem;
	}
	form.search {
		margin-bottom: 2rem;

		.box {
			display: inline-block;
			height: 40px;
			width: 40px;
		}
		/* button { // (the submit button)
			&:focus {
				background-color: $columbian-light;
			}
		} */
	}
	.social {
		a {
			border: 2px solid transparent;
			padding: .5rem;
			border-radius: 100%;
			display: inline-flex;
			transition: 0.3s ease;

			img {
				width: 18px;
				height: 18px;
				transition: filter 0.3s ease;
				filter: saturate(0);
			}
			&:hover {
				background-color: rgba( $columbian-faded, .1 );
				border-color: rgba( $columbian-faded, .1 );
				transform: scale(1.2);
				box-shadow: 3px 3px 10px rgba( $black, 0.05 );

				img {
					filter: saturate(1);
				}
			}
		}
	}
	&.copyright {
		.menu a {
			color: $columbian-faded;
			margin-left: 0.75rem;
			display: inline-block;
		}
	}
}

// remove unnecessary whitespace between footer and ad spot above immediately above it
#ad-default-full-width-above-footer, #ad-instory-full-width-above-footer {
	& + .mt-auto footer.mt-4 {
		margin-top: 0 !important;
	}
}
