@charset "UTF-8";
/*! Customized Bootstrap v4.1.3 */
/*
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* bootstrap defaults (slightly blue hues):
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default; */
/* defaults:
$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default; */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #de0000;
  --orange: #fd7e14;
  --yellow: #ffdf00;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #666666;
  --gray-dark: #333333;
  --columbian: #39759b;
  --columbian-light: #7AC4F4;
  --columbian-faded: #7AA2BC;
  --primary: #007bff;
  --secondary: #666666;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffdf00;
  --danger: #de0000;
  --light: #f8f8f8;
  --dark: #4C4C4C;
  --columbian: #39759b;
  --columbian-light: #7AC4F4;
  --columbian-faded: #7AA2BC;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: "Source Sans Pro", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #39759b;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: rgb(36.4316037736, 74.7806603774, 99.0683962264);
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9C9C9C;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  margin-bottom: 1rem;
}

h1, h2, h3, .h1, .h2, .h3 {
  font-family: "Playfair Display", serif;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-family: "EB Garamond", serif;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-family: "Playfair Display", serif;
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-family: "Playfair Display", serif;
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-family: "Playfair Display", serif;
  font-size: 4.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-family: "Playfair Display", serif;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-family: "Playfair Display", serif;
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.2;
  color: #7AC4F4;
}

/* use font-serif instead
.serif{
  font-family: $font-family-subhead;
} */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #666666;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #B2B2B2;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #666666;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #B2B2B2;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #B2B2B2;
}
.table tbody + tbody {
  border-top: 2px solid #B2B2B2;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #B2B2B2;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #B2B2B2;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(183.6, 218.04, 255);
}

.table-hover .table-primary:hover {
  background-color: rgb(158.1, 204.84, 255);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(158.1, 204.84, 255);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(212.16, 212.16, 212.16);
}

.table-hover .table-secondary:hover {
  background-color: rgb(199.41, 199.41, 199.41);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(199.41, 199.41, 199.41);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(194.8, 230.36, 202.92);
}

.table-hover .table-success:hover {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(190.04, 228.96, 235.12);
}

.table-hover .table-info:hover {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(255, 246.04, 183.6);
}

.table-hover .table-warning:hover {
  background-color: rgb(255, 242.84, 158.1);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(255, 242.84, 158.1);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(245.76, 183.6, 183.6);
}

.table-hover .table-danger:hover {
  background-color: rgb(242.838125, 161.021875, 161.021875);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(242.838125, 161.021875, 161.021875);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(253.04, 253.04, 253.04);
}

.table-hover .table-light:hover {
  background-color: rgb(240.29, 240.29, 240.29);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(240.29, 240.29, 240.29);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(204.88, 204.88, 204.88);
}

.table-hover .table-dark:hover {
  background-color: rgb(192.13, 192.13, 192.13);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(192.13, 192.13, 192.13);
}

.table-columbian,
.table-columbian > th,
.table-columbian > td {
  background-color: rgb(199.56, 216.36, 227);
}

.table-hover .table-columbian:hover {
  background-color: rgb(182.6170469799, 204.5512751678, 218.4429530201);
}
.table-hover .table-columbian:hover > td,
.table-hover .table-columbian:hover > th {
  background-color: rgb(182.6170469799, 204.5512751678, 218.4429530201);
}

.table-columbian-light,
.table-columbian-light > th,
.table-columbian-light > td {
  background-color: rgb(217.76, 238.48, 251.92);
}

.table-hover .table-columbian-light:hover {
  background-color: rgb(194.2079166667, 228.0320833333, 249.9720833333);
}
.table-hover .table-columbian-light:hover > td,
.table-hover .table-columbian-light:hover > th {
  background-color: rgb(194.2079166667, 228.0320833333, 249.9720833333);
}

.table-columbian-faded,
.table-columbian-faded > th,
.table-columbian-faded > td {
  background-color: rgb(217.76, 228.96, 236.24);
}

.table-hover .table-columbian-faded:hover {
  background-color: rgb(200.8025, 217.1025, 227.6975);
}
.table-hover .table-columbian-faded:hover > td,
.table-hover .table-columbian-faded:hover > th {
  background-color: rgb(200.8025, 217.1025, 227.6975);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #191919;
  border-color: rgb(44.125, 44.125, 44.125);
}
.table .thead-light th {
  color: #4C4C4C;
  background-color: #CCCCCC;
  border-color: #B2B2B2;
}

.table-dark {
  color: #fff;
  background-color: #191919;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(44.125, 44.125, 44.125);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.85rem + 2px);
  padding: 0.175rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #4C4C4C;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #4C4C4C;
  border-color: rgb(130.3419811321, 178.5966981132, 209.1580188679);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.25);
}
.form-control::placeholder {
  color: #B2B2B2;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #CCCCCC;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #4C4C4C;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.175rem + 1px);
  padding-bottom: calc(0.175rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.2rem + 1px);
  padding-bottom: calc(0.2rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.175rem;
  padding-bottom: 0.175rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.7125rem + 2px);
  padding: 0.2rem 0.85rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #9C9C9C;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.5rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: rgb(113.4130434783, 221.0869565217, 138);
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: rgb(51.6956521739, 206.3043478261, 87);
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #de0000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(222, 0, 0, 0.9);
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #de0000;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #de0000;
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #de0000;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #de0000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: rgb(255, 94.5, 94.5);
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #ff1212;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(222, 0, 0, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #de0000;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "EB Garamond", serif;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.175rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover, .btn:focus {
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: rgb(0, 104.55, 216.75);
  border-color: rgb(0, 98.4, 204);
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(0, 98.4, 204);
  border-color: rgb(0, 92.25, 191.25);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgb(82.875, 82.875, 82.875);
  border-color: rgb(76.5, 76.5, 76.5);
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgb(76.5, 76.5, 76.5);
  border-color: rgb(70.125, 70.125, 70.125);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: rgb(32.6086956522, 136.1413043478, 56.25);
  border-color: rgb(30.1449275362, 125.8550724638, 52);
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
  border-color: rgb(27.6811594203, 115.5688405797, 47.75);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: rgb(18.75, 132.0652173913, 150);
  border-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
  border-color: rgb(15.9166666667, 112.1086956522, 127.3333333333);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #191919;
  background-color: #ffdf00;
  border-color: #ffdf00;
}
.btn-warning:hover {
  color: #191919;
  background-color: rgb(216.75, 189.55, 0);
  border-color: rgb(204, 178.4, 0);
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 223, 0, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #191919;
  background-color: #ffdf00;
  border-color: #ffdf00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #191919;
  background-color: rgb(204, 178.4, 0);
  border-color: rgb(191.25, 167.25, 0);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 223, 0, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #de0000;
  border-color: #de0000;
}
.btn-danger:hover {
  color: #fff;
  background-color: rgb(183.75, 0, 0);
  border-color: #ab0000;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #de0000;
  border-color: #de0000;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ab0000;
  border-color: rgb(158.25, 0, 0);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.5);
}

.btn-light {
  color: #191919;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-light:hover {
  color: #191919;
  background-color: rgb(228.875, 228.875, 228.875);
  border-color: rgb(222.5, 222.5, 222.5);
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #191919;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #191919;
  background-color: rgb(222.5, 222.5, 222.5);
  border-color: rgb(216.125, 216.125, 216.125);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #4C4C4C;
  border-color: #4C4C4C;
}
.btn-dark:hover {
  color: #fff;
  background-color: rgb(56.875, 56.875, 56.875);
  border-color: rgb(50.5, 50.5, 50.5);
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 76, 76, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #4C4C4C;
  border-color: #4C4C4C;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: rgb(50.5, 50.5, 50.5);
  border-color: rgb(44.125, 44.125, 44.125);
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 76, 76, 0.5);
}

.btn-columbian {
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}
.btn-columbian:hover {
  color: #fff;
  background-color: rgb(46.7158018868, 95.8903301887, 127.0341981132);
  border-color: rgb(43.2877358491, 88.8537735849, 117.7122641509);
}
.btn-columbian:focus, .btn-columbian.focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.5);
}
.btn-columbian.disabled, .btn-columbian:disabled {
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}
.btn-columbian:not(:disabled):not(.disabled):active, .btn-columbian:not(:disabled):not(.disabled).active, .show > .btn-columbian.dropdown-toggle {
  color: #fff;
  background-color: rgb(43.2877358491, 88.8537735849, 117.7122641509);
  border-color: rgb(39.8596698113, 81.8172169811, 108.3903301887);
}
.btn-columbian:not(:disabled):not(.disabled):active:focus, .btn-columbian:not(:disabled):not(.disabled).active:focus, .show > .btn-columbian.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.5);
}

.btn-columbian-light {
  color: #191919;
  background-color: #7AC4F4;
  border-color: #7AC4F4;
}
.btn-columbian-light:hover {
  color: #191919;
  background-color: rgb(86.671875, 180.328125, 241.078125);
  border-color: rgb(74.8958333333, 175.1041666667, 240.1041666667);
}
.btn-columbian-light:focus, .btn-columbian-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 196, 244, 0.5);
}
.btn-columbian-light.disabled, .btn-columbian-light:disabled {
  color: #191919;
  background-color: #7AC4F4;
  border-color: #7AC4F4;
}
.btn-columbian-light:not(:disabled):not(.disabled):active, .btn-columbian-light:not(:disabled):not(.disabled).active, .show > .btn-columbian-light.dropdown-toggle {
  color: #191919;
  background-color: rgb(74.8958333333, 175.1041666667, 240.1041666667);
  border-color: rgb(63.1197916667, 169.8802083333, 239.1302083333);
}
.btn-columbian-light:not(:disabled):not(.disabled):active:focus, .btn-columbian-light:not(:disabled):not(.disabled).active:focus, .show > .btn-columbian-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 196, 244, 0.5);
}

.btn-columbian-faded {
  color: #191919;
  background-color: #7AA2BC;
  border-color: #7AA2BC;
}
.btn-columbian-faded:hover {
  color: #fff;
  background-color: rgb(96.56375, 144.21375, 175.18625);
  border-color: rgb(88.085, 138.285, 170.915);
}
.btn-columbian-faded:focus, .btn-columbian-faded.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 162, 188, 0.5);
}
.btn-columbian-faded.disabled, .btn-columbian-faded:disabled {
  color: #191919;
  background-color: #7AA2BC;
  border-color: #7AA2BC;
}
.btn-columbian-faded:not(:disabled):not(.disabled):active, .btn-columbian-faded:not(:disabled):not(.disabled).active, .show > .btn-columbian-faded.dropdown-toggle {
  color: #fff;
  background-color: rgb(88.085, 138.285, 170.915);
  border-color: rgb(82.49375, 131.74375, 163.75625);
}
.btn-columbian-faded:not(:disabled):not(.disabled):active:focus, .btn-columbian-faded:not(:disabled):not(.disabled).active:focus, .show > .btn-columbian-faded.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 162, 188, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #666666;
  background-color: transparent;
  background-image: none;
  border-color: #666666;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #666666;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffdf00;
  background-color: transparent;
  background-image: none;
  border-color: #ffdf00;
}
.btn-outline-warning:hover {
  color: #191919;
  background-color: #ffdf00;
  border-color: #ffdf00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 223, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffdf00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #191919;
  background-color: #ffdf00;
  border-color: #ffdf00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 223, 0, 0.5);
}

.btn-outline-danger {
  color: #de0000;
  background-color: transparent;
  background-image: none;
  border-color: #de0000;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #de0000;
  border-color: #de0000;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #de0000;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #de0000;
  border-color: #de0000;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.5);
}

.btn-outline-light {
  color: #f8f8f8;
  background-color: transparent;
  background-image: none;
  border-color: #f8f8f8;
}
.btn-outline-light:hover {
  color: #191919;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f8f8;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #191919;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-dark {
  color: #4C4C4C;
  background-color: transparent;
  background-image: none;
  border-color: #4C4C4C;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #4C4C4C;
  border-color: #4C4C4C;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 76, 76, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #4C4C4C;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #4C4C4C;
  border-color: #4C4C4C;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 76, 76, 0.5);
}

.btn-outline-columbian {
  color: #39759b;
  background-color: transparent;
  background-image: none;
  border-color: #39759b;
}
.btn-outline-columbian:hover {
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}
.btn-outline-columbian:focus, .btn-outline-columbian.focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.5);
}
.btn-outline-columbian.disabled, .btn-outline-columbian:disabled {
  color: #39759b;
  background-color: transparent;
}
.btn-outline-columbian:not(:disabled):not(.disabled):active, .btn-outline-columbian:not(:disabled):not(.disabled).active, .show > .btn-outline-columbian.dropdown-toggle {
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}
.btn-outline-columbian:not(:disabled):not(.disabled):active:focus, .btn-outline-columbian:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-columbian.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.5);
}

.btn-outline-columbian-light {
  color: #7AC4F4;
  background-color: transparent;
  background-image: none;
  border-color: #7AC4F4;
}
.btn-outline-columbian-light:hover {
  color: #191919;
  background-color: #7AC4F4;
  border-color: #7AC4F4;
}
.btn-outline-columbian-light:focus, .btn-outline-columbian-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 196, 244, 0.5);
}
.btn-outline-columbian-light.disabled, .btn-outline-columbian-light:disabled {
  color: #7AC4F4;
  background-color: transparent;
}
.btn-outline-columbian-light:not(:disabled):not(.disabled):active, .btn-outline-columbian-light:not(:disabled):not(.disabled).active, .show > .btn-outline-columbian-light.dropdown-toggle {
  color: #191919;
  background-color: #7AC4F4;
  border-color: #7AC4F4;
}
.btn-outline-columbian-light:not(:disabled):not(.disabled):active:focus, .btn-outline-columbian-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-columbian-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 196, 244, 0.5);
}

.btn-outline-columbian-faded {
  color: #7AA2BC;
  background-color: transparent;
  background-image: none;
  border-color: #7AA2BC;
}
.btn-outline-columbian-faded:hover {
  color: #191919;
  background-color: #7AA2BC;
  border-color: #7AA2BC;
}
.btn-outline-columbian-faded:focus, .btn-outline-columbian-faded.focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 162, 188, 0.5);
}
.btn-outline-columbian-faded.disabled, .btn-outline-columbian-faded:disabled {
  color: #7AA2BC;
  background-color: transparent;
}
.btn-outline-columbian-faded:not(:disabled):not(.disabled):active, .btn-outline-columbian-faded:not(:disabled):not(.disabled).active, .show > .btn-outline-columbian-faded.dropdown-toggle {
  color: #191919;
  background-color: #7AA2BC;
  border-color: #7AA2BC;
}
.btn-outline-columbian-faded:not(:disabled):not(.disabled):active:focus, .btn-outline-columbian-faded:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-columbian-faded.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(122, 162, 188, 0.5);
}

.btn-columbian:focus,
.btn-columbian-faded, .btn-columbian-light, .btn-columbian-light:hover,
.btn-columbian-faded.disabled, .btn-columbian-faded:disabled,
.btn-columbian-light.disabled, .btn-columbian-light:disabled,
.btn-columbian-light:not(:disabled):not(.disabled):active {
  color: #fff !important;
}

/* for using with dashicons
.btn-arrow:after{
  content: "\f345";
  display: inline-block;
  width: 15px;
  height: 15px;
  font-size: 15px;
  line-height: 1;
  font-family: dashicons;
  text-decoration: inherit;
  font-weight: 400;
  font-style: normal;
  margin-left: 0.25rem;
  // vertical-align: top;
  text-align: center;
  transition: color .1s ease-in 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-sm.btn-arrow:after{
  width: 10px;
  height: 10px;
  font-size: 10px;
}
.btn-lg.btn-arrow:after{
  width: 20px;
  height: 20px;
  font-size: 20px;
} */
.btn-arrow:after {
  content: "";
  display: inline-block;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right-white.svg);
  background-repeat: no-repeat;
  background-position: center right;
  width: 7px;
  height: 10px;
  background-size: 7px 10px;
  padding-left: 14px;
}
.btn-arrow.btn-sm:after, .btn-group-sm > .btn-arrow.btn:after {
  width: 6px;
  height: 10px;
  background-size: 6px 8px;
  padding-left: 13px;
}
.btn-arrow.btn-lg:after, .btn-group-lg > .btn-arrow.btn:after {
  width: 10px;
  height: 15px;
  padding-left: 18px;
  background-size: 10px 13px;
}

.btn-light.btn-arrow:after, .btn-warning.btn-arrow:after {
  background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right.svg);
}

.btn-pdf:before {
  content: "";
  display: inline-block;
  line-height: 0;
  vertical-align: -6px;
  height: 22px;
  width: 19px;
  margin-right: 0.5rem;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/pdf-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-link {
  font-weight: 400;
  color: #39759b;
  background-color: transparent;
}
.btn-link:hover {
  color: rgb(36.4316037736, 74.7806603774, 99.0683962264);
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #666666;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  font-family: "Playfair Display", serif;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  font-family: "Source Sans Pro", sans-serif;
  padding: 0.2rem 0.85rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  line-height: 1.3;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6375rem;
  padding-left: 0.6375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.175rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4C4C4C;
  text-align: center;
  white-space: nowrap;
  background-color: #CCCCCC;
  border: 1px solid #B2B2B2;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.7125rem + 2px);
  padding: 0.2rem 0.85rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #666666;
}

.nav-tabs {
  border-bottom: 1px solid #B2B2B2;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #CCCCCC #CCCCCC #B2B2B2;
}
.nav-tabs .nav-link.disabled {
  color: #666666;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #4C4C4C;
  background-color: #fff;
  border-color: #B2B2B2 #B2B2B2 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #39759b;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f8f8f8;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
}

.card-img-top {
  width: 100%;
}

.card-img-bottom {
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .card-columns {
    column-count: 4;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .card-columns {
    column-count: 3;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .card-columns {
    column-count: 2;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f8f8f8;
}

.breadcrumb-item {
  margin-bottom: 0;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #666666;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #666666;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #39759b;
  background-color: #fff;
  border: 1px solid #B2B2B2;
  transition: background-color 0.15s ease-in-out;
}
.page-link:hover {
  z-index: 2;
  color: rgb(36.4316037736, 74.7806603774, 99.0683962264);
  text-decoration: none;
  background-color: rgba(204, 204, 204, 0.5);
  border-color: #B2B2B2;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}
.page-item.disabled .page-link {
  color: #666666;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #B2B2B2;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0.5rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(0, 98.4, 204);
}

.badge-secondary {
  color: #fff;
  background-color: #666666;
}
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(76.5, 76.5, 76.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(30.1449275362, 125.8550724638, 52);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667);
}

.badge-warning {
  color: #191919;
  background-color: #ffdf00;
}
.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #191919;
  text-decoration: none;
  background-color: rgb(204, 178.4, 0);
}

.badge-danger {
  color: #fff;
  background-color: #de0000;
}
.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ab0000;
}

.badge-light {
  color: #191919;
  background-color: #f8f8f8;
}
.badge-light[href]:hover, .badge-light[href]:focus {
  color: #191919;
  text-decoration: none;
  background-color: rgb(222.5, 222.5, 222.5);
}

.badge-dark {
  color: #fff;
  background-color: #4C4C4C;
}
.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(50.5, 50.5, 50.5);
}

.badge-columbian {
  color: #fff;
  background-color: #39759b;
}
.badge-columbian[href]:hover, .badge-columbian[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: rgb(43.2877358491, 88.8537735849, 117.7122641509);
}

.badge-columbian-light {
  color: #191919;
  background-color: #7AC4F4;
}
.badge-columbian-light[href]:hover, .badge-columbian-light[href]:focus {
  color: #191919;
  text-decoration: none;
  background-color: rgb(74.8958333333, 175.1041666667, 240.1041666667);
}

.badge-columbian-faded {
  color: #191919;
  background-color: #7AA2BC;
}
.badge-columbian-faded[href]:hover, .badge-columbian-faded[href]:focus {
  color: #191919;
  text-decoration: none;
  background-color: rgb(88.085, 138.285, 170.915);
}

.badge-columbian-faded,
.badge-columbian-light {
  color: #fff;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(204, 228.6, 255);
  border-color: rgb(183.6, 218.04, 255);
}
.alert-primary hr {
  border-top-color: rgb(158.1, 204.84, 255);
}
.alert-primary .alert-link {
  color: rgb(0, 39.36, 81.6);
}

.alert-secondary {
  color: rgb(53.04, 53.04, 53.04);
  background-color: rgb(224.4, 224.4, 224.4);
  border-color: rgb(212.16, 212.16, 212.16);
}
.alert-secondary hr {
  border-top-color: rgb(199.41, 199.41, 199.41);
}
.alert-secondary .alert-link {
  color: rgb(27.54, 27.54, 27.54);
}

.alert-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
.alert-success hr {
  border-top-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.alert-success .alert-link {
  color: rgb(10.9449275362, 45.6950724638, 18.88);
}

.alert-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(208.6, 236.4, 240.8);
  border-color: rgb(190.04, 228.96, 235.12);
}
.alert-info hr {
  border-top-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.alert-info .alert-link {
  color: rgb(6.2933333333, 44.3269565217, 50.3466666667);
}

.alert-warning {
  color: rgb(132.6, 115.96, 0);
  background-color: rgb(255, 248.6, 204);
  border-color: rgb(255, 246.04, 183.6);
}
.alert-warning hr {
  border-top-color: rgb(255, 242.84, 158.1);
}
.alert-warning .alert-link {
  color: rgb(81.6, 71.36, 0);
}

.alert-danger {
  color: rgb(115.44, 0, 0);
  background-color: rgb(248.4, 204, 204);
  border-color: rgb(245.76, 183.6, 183.6);
}
.alert-danger hr {
  border-top-color: rgb(242.838125, 161.021875, 161.021875);
}
.alert-danger .alert-link {
  color: rgb(64.44, 0, 0);
}

.alert-light {
  color: rgb(128.96, 128.96, 128.96);
  background-color: rgb(253.6, 253.6, 253.6);
  border-color: rgb(253.04, 253.04, 253.04);
}
.alert-light hr {
  border-top-color: rgb(240.29, 240.29, 240.29);
}
.alert-light .alert-link {
  color: rgb(103.46, 103.46, 103.46);
}

.alert-dark {
  color: rgb(39.52, 39.52, 39.52);
  background-color: rgb(219.2, 219.2, 219.2);
  border-color: rgb(204.88, 204.88, 204.88);
}
.alert-dark hr {
  border-top-color: rgb(192.13, 192.13, 192.13);
}
.alert-dark .alert-link {
  color: rgb(14.02, 14.02, 14.02);
}

.alert-columbian {
  color: rgb(29.64, 60.84, 80.6);
  background-color: rgb(215.4, 227.4, 235);
  border-color: rgb(199.56, 216.36, 227);
}
.alert-columbian hr {
  border-top-color: rgb(182.6170469799, 204.5512751678, 218.4429530201);
}
.alert-columbian .alert-link {
  color: rgb(15.9277358491, 32.6937735849, 43.3122641509);
}

.alert-columbian-light {
  color: rgb(63.44, 101.92, 126.88);
  background-color: rgb(228.4, 243.2, 252.8);
  border-color: rgb(217.76, 238.48, 251.92);
}
.alert-columbian-light hr {
  border-top-color: rgb(194.2079166667, 228.0320833333, 249.9720833333);
}
.alert-columbian-light .alert-link {
  color: rgb(46.44, 74.6085245902, 92.88);
}

.alert-columbian-faded {
  color: rgb(63.44, 84.24, 97.76);
  background-color: rgb(228.4, 236.4, 241.6);
  border-color: rgb(217.76, 228.96, 236.24);
}
.alert-columbian-faded hr {
  border-top-color: rgb(200.8025, 217.1025, 227.6975);
}
.alert-columbian-faded .alert-link {
  color: rgb(43.3690322581, 57.5883870968, 66.8309677419);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #CCCCCC;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #4C4C4C;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #4C4C4C;
  text-decoration: none;
  background-color: #f8f8f8;
}
.list-group-item-action:active {
  color: #000;
  background-color: #CCCCCC;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:last-child {
  margin-bottom: 0;
}
.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #666666;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #39759b;
  border-color: #39759b;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(183.6, 218.04, 255);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: rgb(0, 63.96, 132.6);
  background-color: rgb(158.1, 204.84, 255);
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(0, 63.96, 132.6);
  border-color: rgb(0, 63.96, 132.6);
}

.list-group-item-secondary {
  color: rgb(53.04, 53.04, 53.04);
  background-color: rgb(212.16, 212.16, 212.16);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: rgb(53.04, 53.04, 53.04);
  background-color: rgb(199.41, 199.41, 199.41);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(53.04, 53.04, 53.04);
  border-color: rgb(53.04, 53.04, 53.04);
}

.list-group-item-success {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(194.8, 230.36, 202.92);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(176.7059405941, 222.9540594059, 187.2665346535);
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: rgb(20.8, 86.84, 35.88);
  border-color: rgb(20.8, 86.84, 35.88);
}

.list-group-item-info {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(190.04, 228.96, 235.12);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: rgb(11.96, 84.24, 95.68);
  background-color: rgb(170.5152475248, 221.1332673267, 229.1447524752);
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: rgb(11.96, 84.24, 95.68);
  border-color: rgb(11.96, 84.24, 95.68);
}

.list-group-item-warning {
  color: rgb(132.6, 115.96, 0);
  background-color: rgb(255, 246.04, 183.6);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: rgb(132.6, 115.96, 0);
  background-color: rgb(255, 242.84, 158.1);
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 115.96, 0);
  border-color: rgb(132.6, 115.96, 0);
}

.list-group-item-danger {
  color: rgb(115.44, 0, 0);
  background-color: rgb(245.76, 183.6, 183.6);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: rgb(115.44, 0, 0);
  background-color: rgb(242.838125, 161.021875, 161.021875);
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: rgb(115.44, 0, 0);
  border-color: rgb(115.44, 0, 0);
}

.list-group-item-light {
  color: rgb(128.96, 128.96, 128.96);
  background-color: rgb(253.04, 253.04, 253.04);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: rgb(128.96, 128.96, 128.96);
  background-color: rgb(240.29, 240.29, 240.29);
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(128.96, 128.96, 128.96);
  border-color: rgb(128.96, 128.96, 128.96);
}

.list-group-item-dark {
  color: rgb(39.52, 39.52, 39.52);
  background-color: rgb(204.88, 204.88, 204.88);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgb(39.52, 39.52, 39.52);
  background-color: rgb(192.13, 192.13, 192.13);
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgb(39.52, 39.52, 39.52);
  border-color: rgb(39.52, 39.52, 39.52);
}

.list-group-item-columbian {
  color: rgb(29.64, 60.84, 80.6);
  background-color: rgb(199.56, 216.36, 227);
}
.list-group-item-columbian.list-group-item-action:hover, .list-group-item-columbian.list-group-item-action:focus {
  color: rgb(29.64, 60.84, 80.6);
  background-color: rgb(182.6170469799, 204.5512751678, 218.4429530201);
}
.list-group-item-columbian.list-group-item-action.active {
  color: #fff;
  background-color: rgb(29.64, 60.84, 80.6);
  border-color: rgb(29.64, 60.84, 80.6);
}

.list-group-item-columbian-light {
  color: rgb(63.44, 101.92, 126.88);
  background-color: rgb(217.76, 238.48, 251.92);
}
.list-group-item-columbian-light.list-group-item-action:hover, .list-group-item-columbian-light.list-group-item-action:focus {
  color: rgb(63.44, 101.92, 126.88);
  background-color: rgb(194.2079166667, 228.0320833333, 249.9720833333);
}
.list-group-item-columbian-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(63.44, 101.92, 126.88);
  border-color: rgb(63.44, 101.92, 126.88);
}

.list-group-item-columbian-faded {
  color: rgb(63.44, 84.24, 97.76);
  background-color: rgb(217.76, 228.96, 236.24);
}
.list-group-item-columbian-faded.list-group-item-action:hover, .list-group-item-columbian-faded.list-group-item-action:focus {
  color: rgb(63.44, 84.24, 97.76);
  background-color: rgb(200.8025, 217.1025, 227.6975);
}
.list-group-item-columbian-faded.list-group-item-action.active {
  color: #fff;
  background-color: rgb(63.44, 84.24, 97.76);
  border-color: rgb(63.44, 84.24, 97.76);
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10003;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 0.5rem * 2);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 0.5rem * 2);
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10002;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.9;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #E5E5E5;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #E5E5E5;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 60%;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - 1.75rem * 2);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 1.75rem * 2);
  }
  .modal-sm {
    max-width: 40%;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 80%;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.tooltip .badge {
  padding: 3px 4px;
  vertical-align: 2px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid rgb(247.35, 247.35, 247.35);
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: rgb(247.35, 247.35, 247.35);
  border-bottom: 1px solid rgb(234.6, 234.6, 234.6);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(0, 98.4, 204) !important;
}

.bg-secondary {
  background-color: #666666 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(76.5, 76.5, 76.5) !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(30.1449275362, 125.8550724638, 52) !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.bg-warning {
  background-color: #ffdf00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(204, 178.4, 0) !important;
}

.bg-danger {
  background-color: #de0000 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ab0000 !important;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(222.5, 222.5, 222.5) !important;
}

.bg-dark {
  background-color: #4C4C4C !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(50.5, 50.5, 50.5) !important;
}

.bg-columbian {
  background-color: #39759b !important;
}

a.bg-columbian:hover, a.bg-columbian:focus,
button.bg-columbian:hover,
button.bg-columbian:focus {
  background-color: rgb(43.2877358491, 88.8537735849, 117.7122641509) !important;
}

.bg-columbian-light {
  background-color: #7AC4F4 !important;
}

a.bg-columbian-light:hover, a.bg-columbian-light:focus,
button.bg-columbian-light:hover,
button.bg-columbian-light:focus {
  background-color: rgb(74.8958333333, 175.1041666667, 240.1041666667) !important;
}

.bg-columbian-faded {
  background-color: #7AA2BC !important;
}

a.bg-columbian-faded:hover, a.bg-columbian-faded:focus,
button.bg-columbian-faded:hover,
button.bg-columbian-faded:focus {
  background-color: rgb(88.085, 138.285, 170.915) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #B2B2B2 !important;
}

.border-top {
  border-top: 1px solid #B2B2B2 !important;
}

.border-right {
  border-right: 1px solid #B2B2B2 !important;
}

.border-bottom {
  border-bottom: 1px solid #B2B2B2 !important;
}

.border-left {
  border-left: 1px solid #B2B2B2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #666666 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffdf00 !important;
}

.border-danger {
  border-color: #de0000 !important;
}

.border-light {
  border-color: #f8f8f8 !important;
}

.border-dark {
  border-color: #4C4C4C !important;
}

.border-columbian {
  border-color: #39759b !important;
}

.border-columbian-light {
  border-color: #7AC4F4 !important;
}

.border-columbian-faded {
  border-color: #7AA2BC !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: #E5E5E5 !important;
}

.border-thick {
  border-width: 3px !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.form-control-lg.rounded {
  border-radius: 0.5rem !important;
}

.form-control-lg.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.form-control-lg.rounded-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.form-control-lg.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.form-control-lg.rounded-left {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border-top {
  border-top: 1px solid #B2B2B2;
}

.border-right {
  border-right: 1px solid #B2B2B2;
}

.border-bottom {
  border-bottom: 1px solid #B2B2B2;
}

.border-left {
  border-left: 1px solid #B2B2B2;
}

.border-top-0 {
  border-top: 0;
}

.border-right-0 {
  border-right: 0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-left-0 {
  border-left: 0;
}

.border-x {
  border-left: 1px solid #B2B2B2;
  border-right: 1px solid #B2B2B2;
}

.border-y {
  border-top: 1px solid #B2B2B2;
  border-bottom: 1px solid #B2B2B2;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #B2B2B2;
  }
  .border-sm-right {
    border-right: 1px solid #B2B2B2;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #B2B2B2;
  }
  .border-sm-left {
    border-left: 1px solid #B2B2B2;
  }
  .border-sm-top-0 {
    border-top: 0;
  }
  .border-sm-right-0 {
    border-right: 0;
  }
  .border-sm-bottom-0 {
    border-bottom: 0;
  }
  .border-sm-left-0 {
    border-left: 0;
  }
  .border-sm-x {
    border-left: 1px solid #B2B2B2;
    border-right: 1px solid #B2B2B2;
  }
  .border-sm-y {
    border-top: 1px solid #B2B2B2;
    border-bottom: 1px solid #B2B2B2;
  }
}
@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #B2B2B2;
  }
  .border-md-right {
    border-right: 1px solid #B2B2B2;
  }
  .border-md-bottom {
    border-bottom: 1px solid #B2B2B2;
  }
  .border-md-left {
    border-left: 1px solid #B2B2B2;
  }
  .border-md-top-0 {
    border-top: 0;
  }
  .border-md-right-0 {
    border-right: 0;
  }
  .border-md-bottom-0 {
    border-bottom: 0;
  }
  .border-md-left-0 {
    border-left: 0;
  }
  .border-md-x {
    border-left: 1px solid #B2B2B2;
    border-right: 1px solid #B2B2B2;
  }
  .border-md-y {
    border-top: 1px solid #B2B2B2;
    border-bottom: 1px solid #B2B2B2;
  }
}
@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #B2B2B2;
  }
  .border-lg-right {
    border-right: 1px solid #B2B2B2;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #B2B2B2;
  }
  .border-lg-left {
    border-left: 1px solid #B2B2B2;
  }
  .border-lg-top-0 {
    border-top: 0;
  }
  .border-lg-right-0 {
    border-right: 0;
  }
  .border-lg-bottom-0 {
    border-bottom: 0;
  }
  .border-lg-left-0 {
    border-left: 0;
  }
  .border-lg-x {
    border-left: 1px solid #B2B2B2;
    border-right: 1px solid #B2B2B2;
  }
  .border-lg-y {
    border-top: 1px solid #B2B2B2;
    border-bottom: 1px solid #B2B2B2;
  }
}
@media (min-width: 1440px) {
  .border-xl-top {
    border-top: 1px solid #B2B2B2;
  }
  .border-xl-right {
    border-right: 1px solid #B2B2B2;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #B2B2B2;
  }
  .border-xl-left {
    border-left: 1px solid #B2B2B2;
  }
  .border-xl-top-0 {
    border-top: 0;
  }
  .border-xl-right-0 {
    border-right: 0;
  }
  .border-xl-bottom-0 {
    border-bottom: 0;
  }
  .border-xl-left-0 {
    border-left: 0;
  }
  .border-xl-x {
    border-left: 1px solid #B2B2B2;
    border-right: 1px solid #B2B2B2;
  }
  .border-xl-y {
    border-top: 1px solid #B2B2B2;
    border-bottom: 1px solid #B2B2B2;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.w-fit-content {
  width: fit-content !important;
}

.h-fit-content {
  height: fit-content !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(0, 98.4, 204) !important;
}

.text-secondary {
  color: #666666 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(76.5, 76.5, 76.5) !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(30.1449275362, 125.8550724638, 52) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(17.3333333333, 122.0869565217, 138.6666666667) !important;
}

.text-warning {
  color: #ffdf00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(204, 178.4, 0) !important;
}

.text-danger {
  color: #de0000 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ab0000 !important;
}

.text-light {
  color: #f8f8f8 !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(222.5, 222.5, 222.5) !important;
}

.text-dark {
  color: #4C4C4C !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(50.5, 50.5, 50.5) !important;
}

.text-columbian {
  color: #39759b !important;
}

a.text-columbian:hover, a.text-columbian:focus {
  color: rgb(43.2877358491, 88.8537735849, 117.7122641509) !important;
}

.text-columbian-light {
  color: #7AC4F4 !important;
}

a.text-columbian-light:hover, a.text-columbian-light:focus {
  color: rgb(74.8958333333, 175.1041666667, 240.1041666667) !important;
}

.text-columbian-faded {
  color: #7AA2BC !important;
}

a.text-columbian-faded:hover, a.text-columbian-faded:focus {
  color: rgb(88.085, 138.285, 170.915) !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #9C9C9C !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #7F7F7F;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #B2B2B2 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #B2B2B2;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #B2B2B2;
  }
}
/*! Theme styles */
#top-bar {
  z-index: 10002;
  background-color: rgba(255, 255, 255, 0.95);
}
@media print {
  #top-bar {
    position: static !important;
  }
}
#top-bar #open-menu-btn {
  font-size: 18px;
  /* svg {
  	vertical-align: -4px;
  	opacity: .67;
  } */
}
#top-bar #open-menu-btn .menu-icon {
  display: inline-block;
  height: 12px;
  margin-right: 3px;
}
@media (max-width: 575.98px) {
  #top-bar #open-menu-btn .menu-icon {
    margin-right: 0;
  }
}
#top-bar #open-menu-btn .menu-icon span,
#top-bar #open-menu-btn .menu-icon span:before,
#top-bar #open-menu-btn .menu-icon span:after {
  width: 20px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.33);
  content: "";
  position: relative;
  display: block;
  transition: 0.15s background-color ease-in-out, 0.5s margin-top ease, 0.5s transform ease, 0.5s -webkit-transform ease;
}
#top-bar #open-menu-btn .menu-icon span:before {
  top: 5px;
}
#top-bar #open-menu-btn .menu-icon span:after {
  top: 8px;
}
#top-bar.menu-show #open-menu-btn .menu-icon span {
  margin-top: 5px;
  transform: rotate(-45deg);
}
#top-bar.menu-show #open-menu-btn .menu-icon span:after {
  margin-top: -10px;
  transform: rotate(90deg);
}
#top-bar.menu-show #open-menu-btn .menu-icon span:before {
  transform: rotate(45deg);
  opacity: 0;
}

#top-bar #open-menu-btn:hover .menu-icon span, #top-bar #open-menu-btn:hover .menu-icon span:before, #top-bar #open-menu-btn:hover .menu-icon span:after, .menu-show #top-bar #open-menu-btn .menu-icon span, .menu-show #top-bar #open-menu-btn .menu-icon span:before, .menu-show #top-bar #open-menu-btn .menu-icon span:after {
  background-color: rgba(255, 255, 255, 0.5);
}
#top-bar #open-menu-btn .menu-btn-label {
  letter-spacing: -0.04rem;
}
#top-bar .btn {
  font-size: 16px;
}
@media (min-width: 992px) {
  #top-bar #logo {
    max-width: 355px;
    padding: 6px 0;
  }
}
@media (max-width: 991.98px) {
  #top-bar #logo {
    max-width: 230px;
    padding: 2px 0;
  }
}
#top-bar #logo-tagline {
  overflow-y: hidden;
}
@media (min-width: 992px) {
  #top-bar #logo-tagline img {
    max-width: 290px;
  }
}
@media (max-width: 991.98px) {
  #top-bar #logo-tagline img {
    max-width: 190px;
  }
}
#top-bar.position-fixed + div {
  margin-top: 67px;
}
#top-bar.position-sticky, #top-bar.position-fixed {
  top: 0;
  transition: 0.2s ease;
}
@media (max-width: 991.98px) {
  #sentinal-top.has-top-ad + #top-bar.position-sticky, #sentinal-top.has-top-ad + #top-bar.position-fixed {
    top: -1px;
  }
}
#top-bar.position-sticky #logo, #top-bar.position-fixed #logo {
  transition: max-width 0.2s ease;
}
#top-bar.position-sticky #logo-tagline, #top-bar.position-fixed #logo-tagline {
  transition: height 0.2s ease;
  height: 19px;
  padding-top: 6px;
}
@media (max-width: 767.98px) {
  #top-bar.position-sticky #logo-tagline, #top-bar.position-fixed #logo-tagline {
    padding-top: 0;
  }
}
#top-bar.position-sticky #misc-btns .subscribe-btn, #top-bar.position-fixed #misc-btns .subscribe-btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (max-width: 767.98px) {
  #top-bar.position-sticky #misc-btns .subscribe-btn, #top-bar.position-fixed #misc-btns .subscribe-btn {
    transition: width 0.2s ease, padding 0.2s ease, border-width 0.2s ease;
    overflow-x: hidden;
    width: 0;
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
#top-bar.position-sticky.stuck, #top-bar.position-fixed.stuck {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
}
@media (min-width: 992px) {
  #top-bar.position-sticky.stuck, #top-bar.position-fixed.stuck {
    margin-bottom: 26px;
  }
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    max-width: 170px;
  }
}
@media (min-width: 1440px) {
  #top-bar.position-sticky.stuck, #top-bar.position-fixed.stuck {
    margin-bottom: 44px;
  }
}
@media screen and (max-width: 435px) {
  #top-bar.position-sticky.stuck #logo-col, #top-bar.position-fixed.stuck #logo-col {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    max-height: none;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 575.98px), (-ms-high-contrast: none) and (max-width: 575.98px) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    width: 157px;
    height: 27px;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 576px) and (max-width: 767.98px), (-ms-high-contrast: none) and (min-width: 576px) and (max-width: 767.98px) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    width: 251px;
    height: 44px;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 768px) and (max-width: 991.98px), (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 991.98px) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    width: 221px;
    height: 39px;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 992px) and (max-width: 1439.98px), (-ms-high-contrast: none) and (min-width: 992px) and (max-width: 1439.98px) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    width: 296px;
    height: 52px;
  }
}
@media screen and (-ms-high-contrast: active) and (min-width: 1440px), (-ms-high-contrast: none) and (min-width: 1440px) {
  #top-bar.position-sticky.stuck #logo, #top-bar.position-fixed.stuck #logo {
    width: 445px;
    height: 78px;
  }
}
#top-bar.position-sticky.stuck #logo-tagline, #top-bar.position-fixed.stuck #logo-tagline {
  height: 0;
  padding-top: 0;
}
@media (max-width: 767.98px) {
  #top-bar.position-sticky.stuck #misc-btns .subscribe-btn, #top-bar.position-fixed.stuck #misc-btns .subscribe-btn {
    width: 100px;
    border-width: 1px;
    padding-left: 0.85rem;
    padding-right: 0.85rem;
  }
}
@media screen and (max-width: 767.98px) and (max-width: 435px) {
  #top-bar.position-sticky.stuck #misc-btns .subscribe-btn, #top-bar.position-fixed.stuck #misc-btns .subscribe-btn {
    width: auto;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    font-size: 3vw;
  }
}
@media screen and (max-width: 782.98px) {
  body.admin-bar #top-bar.position-sticky, body.admin-bar #top-bar.position-fixed {
    top: 46px;
  }
}
@media screen and (min-width: 783px) {
  body.admin-bar #top-bar.position-sticky, body.admin-bar #top-bar.position-fixed {
    top: 32px;
  }
}
#top-bar #top-nav-my-account-btn svg {
  vertical-align: 0;
  margin-right: 3px;
  opacity: 0.67;
}
@media (max-width: 575.98px) {
  #top-bar #top-nav-my-account-btn svg {
    margin-right: 0;
    vertical-align: sub;
    width: 16px;
    height: auto;
  }
}
@media (max-width: 767.98px) {
  #top-bar #top-nav-my-account-btn.shares-col-with-subscribe-btn svg {
    margin-right: 0;
    vertical-align: sub;
    width: 16px;
    height: auto;
  }
}
#top-bar #top-nav-my-account-btn + #account-menu {
  overflow: hidden;
  position: absolute;
  top: 33px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: 0.5s ease;
  background-color: #fff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
  z-index: 10003;
}
#top-bar #top-nav-my-account-btn + #account-menu .inside {
  padding: 0.75rem;
  min-width: 15rem;
  max-width: 90vw;
}
#top-bar #top-nav-my-account-btn + #account-menu .inside.logged-in .username {
  text-overflow: ellipsis;
  overflow: hidden;
}
#top-bar #top-nav-my-account-btn + #account-menu .inside.logged-in .username:empty {
  display: none;
}
#top-bar #top-nav-my-account-btn + #account-menu .inside:active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
#top-bar #top-nav-my-account-btn:focus + #account-menu {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
#top-bar #top-nav-search-btn svg {
  vertical-align: -5px;
}

#supplement-bar-mobile {
  font-size: 85%;
}
#supplement-bar-mobile section .item {
  color: #666666;
}
#supplement-bar-mobile section:first-child .item:not(:last-child) {
  margin-right: 0.75rem;
  padding-right: 0.75rem;
  border-right: 1px solid #E5E5E5;
}
#supplement-bar-mobile section:last-child .item:not(:first-child) {
  margin-left: 0.75rem;
  padding-left: 0.75rem;
  border-left: 1px solid #E5E5E5;
}

#supplement-bar {
  font-size: 14px;
  color: #9C9C9C;
}
#supplement-bar #supplement-bar-date {
  padding-top: 0.67rem;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  height: 2rem;
}
#supplement-bar nav ul {
  margin-bottom: 0.5rem;
}
#supplement-bar nav ul li.menu-item {
  margin-right: 0;
  display: inline-block;
}
#supplement-bar nav ul li.menu-item a.nav-link {
  display: block;
  font-size: 16px;
  color: #9C9C9C;
  transition: border-bottom 0.2s ease;
}
#supplement-bar nav ul li.menu-item a.nav-link.current {
  font-weight: bold;
}
#supplement-bar nav ul li.menu-item a.nav-link.current:after {
  background-color: rgba(156, 156, 156, 0.4);
}
#supplement-bar nav ul li.menu-item a.nav-link:hover {
  text-decoration: none;
}
#supplement-bar nav ul li.menu-item a.nav-link:after {
  content: "";
  width: 0;
  height: 3px;
  margin-top: 4px;
  background-color: rgba(122, 162, 188, 0.4);
  display: block;
  transition: 0.2s ease;
}
#supplement-bar nav ul li.menu-item a.nav-link.text-success:after {
  background-color: rgba(40, 167, 69, 0.4);
}
#supplement-bar nav ul li.menu-item a.nav-link:hover:after {
  width: 100%;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children {
  cursor: pointer;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link {
  color: #7AA2BC !important;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link:after {
  border-color: rgba(122, 162, 188, 0.5);
  border-width: 0 3px 3px 0;
  margin-right: 0;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link + .dropdown-menu, #supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link + .dropdown-menu:hover {
  visibility: visible;
  opacity: 1;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link + .dropdown-menu .dropdown-menu-inside, #supplement-bar nav ul li.menu-item.menu-item-has-children:hover a.nav-link + .dropdown-menu:hover .dropdown-menu-inside {
  top: 0.5rem;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children a.nav-link {
  display: inline-block;
  transition: color 0.15s ease-in-out;
  pointer-events: none;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children a.nav-link:after {
  content: "";
  display: inline-block;
  width: auto;
  float: right;
  background-color: transparent;
  border: solid rgba(0, 0, 0, 0.2);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  padding: 2px;
  margin-top: 0.5rem;
  margin-left: 0.4rem;
  transition: border 0.15s ease-in-out, margin-right 0.15s ease-in-out;
  margin-right: 1px;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 3;
  transition: opacity 0.15s ease-in-out;
  right: 0;
  cursor: auto;
  width: 60%;
}
@media (max-width: 1439.98px) {
  #supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu {
    width: 75%;
  }
}
#supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu .dropdown-menu-inside {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0.75rem 0;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0rem 0.5rem 1.5rem rgba(0, 0, 0, 0.125);
  border-top: 1px solid #E5E5E5;
  transition: top 0.15s ease-in-out;
  align-items: center;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu .dropdown-menu-inside a.dropdown-item {
  display: inline-block;
  padding: 0.75rem 2rem;
  color: #333333;
  transition: background-color 0.2s ease;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  text-align: left;
  font-size: 100%;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu .dropdown-menu-inside a.dropdown-item.current {
  color: #000;
  background-color: rgba(204, 204, 204, 0.25);
  font-weight: bold;
}
#supplement-bar nav ul li.menu-item.menu-item-has-children .dropdown-menu .dropdown-menu-inside a.dropdown-item:hover {
  color: #000;
  text-decoration: none;
  background-color: rgba(178, 178, 178, 0.5);
}
#supplement-bar #weather-widget {
  padding-top: 0.3rem;
}
#supplement-bar #weather-widget .btn {
  line-height: 1;
}

#content-overlay, #media-viewer {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease;
}
#content-overlay button.close, #media-viewer button.close {
  position: absolute;
  top: 8rem;
  right: 1.5rem;
  font-size: 2rem;
  color: #fff;
  line-height: 0.5;
  opacity: inherit;
  outline: 0;
  z-index: 1;
  text-shadow: none;
  font-weight: 400;
  /* @include media-breakpoint-down(sm) {
  	& {
  		display: none;
  	}
  } */
}
#content-overlay button.close span, #media-viewer button.close span {
  display: inline-block;
  padding: 0.7rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out, box-shadow 0.3s ease;
}
#content-overlay button.close span:hover, #media-viewer button.close span:hover {
  background-color: #fff;
  box-shadow: 0px 0px 0 5px rgba(122, 162, 188, 0.6), 0px 0px 0 10px rgba(122, 196, 244, 0.3);
  color: #7AA2BC;
}

body.overlay-show #content-overlay, body.media-viewer-open #media-viewer {
  visibility: visible;
  opacity: 1;
}
body.overlay-show, body.media-viewer-open {
  overflow: hidden;
}

#media-viewer {
  z-index: 9998;
  color: #fff;
}
#media-viewer .inside-content {
  position: absolute;
  top: calc(68px + 1rem);
  bottom: 1rem;
  width: 100%;
}
@media (max-width: 767.98px) {
  #media-viewer .inside-content {
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  #media-viewer .inside-content .media {
    height: 100%;
  }
}
#media-viewer .inside-content .media span {
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  /* &:hover .external-actions {
  	opacity: 1;
  	background-color: rgba($black, 0.1);
  }
  .external-actions {
  	position: absolute;
  	top: 0;
  	right: 0;
  	padding: 0.5rem;
  	transition: 0.2s ease;
  	opacity: 0.5;

  	a {
  		display: inline-block;
  		line-height: 1;

  		img {
  			width: 23px;
  			height: 23px;
  			padding: 2px;
  		}
  	}
  } */
}
#media-viewer .inside-content .media span img#photo {
  margin: auto;
  max-height: 100%;
  cursor: pointer;
  align-self: center;
  cursor: zoom-out;
}
@media (max-width: 767.98px) {
  #media-viewer .inside-content .media span img#photo {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  #media-viewer .inside-content .details {
    border-left: 1px solid #4C4C4C;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
@media (max-width: 767.98px) {
  #media-viewer .inside-content .details {
    margin-top: auto;
  }
}
#media-viewer .inside-content .details button.close {
  position: static;
  margin-bottom: 1rem;
  cursor: zoom-out;
  width: 43px;
  align-self: flex-end;
  top: 6rem;
}
#media-viewer .inside-content .details .title:empty, #media-viewer .inside-content .details .pagination:empty {
  display: none;
}
#media-viewer .inside-content .details .caption {
  font-size: 0.9rem;
}
#media-viewer .inside-content .details nav {
  text-align: center;
}
#media-viewer .inside-content .details nav button {
  padding: 0.25rem;
  line-height: 1;
}
#media-viewer .inside-content .details nav button img {
  width: 14px;
  height: 20px;
}
#media-viewer .inside-content .details nav button[data-direction=prev] img {
  transform: rotate(180deg);
}
#media-viewer .inside-content .details .external-actions {
  line-height: 3rem;
}
#media-viewer .inside-content .details .external-actions .btn:hover {
  background-color: #39759b;
}
body.admin-bar #media-viewer .inside-content {
  top: calc(68px + 1rem + 32px);
}

@media screen and (min-width: 601px) and (max-width: 782.98px) {
  body.admin-bar #media-viewer .inside-content {
    top: calc(68px + 1rem + 46px);
  }
}

#sentinal-top {
  /* visibility: visible;
  opacity: 1;
  max-height: 400px;
  overflow: hidden;
  transition: 0.5s ease; */
}
body.overlay-show #sentinal-top, body.media-viewer-open #sentinal-top {
  /* visibility: hidden;
  opacity: 0;
  max-height: 0; */
  display: none;
}

/* #top-bar.menu-show #open-menu-btn svg {
	line {
		transition: 0.5s ease;
	}
	line:nth-child(1) {
		transform: rotate(45deg);
	}
	line:nth-child(2) {
		transform: rotate(-45deg);
	}
	line:nth-child(3) {
		// transform: rotate(45deg);
		opacity:0;
	}
} */
nav#nav-main {
  position: absolute;
  top: 3rem;
  z-index: 10001;
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}
#top-bar nav#nav-main {
  transition: 0.5s ease;
}
#top-bar.menu-show nav#nav-main {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}
#top-bar.menu-hide nav#nav-main {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%);
}
nav#nav-main .display-username .username {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: rgb(43.3690322581, 57.5883870968, 66.8309677419);
  font-weight: 700;
}
nav#nav-main .display-username .username:empty {
  display: none !important;
}
nav#nav-main .display-username .username:not(:empty):before {
  content: "Logged in as ";
  font-weight: 400;
  color: rgb(63.44, 84.24, 97.76);
}
nav#nav-main #main-menu-top-items .icon {
  margin-right: 0.35rem;
  opacity: 0.67;
}
nav#nav-main ul {
  min-width: 30%;
  margin-bottom: 0;
}
nav#nav-main ul li.menu-item.separate {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid #E5E5E5;
}
nav#nav-main ul li.menu-item .menu-item-inside a.nav-link {
  display: block;
  padding: 1rem 2rem 1rem 3rem;
  color: #333333;
  font-family: "Playfair Display", serif;
  font-size: 1.1rem;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  width: 25rem;
}
nav#nav-main ul li.menu-item .menu-item-inside a.nav-link:not([href]), nav#nav-main ul li.menu-item .menu-item-inside a.nav-link[href="javascript:;"] {
  cursor: default;
  outline: 0;
}
nav#nav-main ul li.menu-item .menu-item-inside a.nav-link.current {
  color: #000;
  background-color: rgba(204, 204, 204, 0.25);
  font-weight: bold;
}
nav#nav-main ul li.menu-item .menu-item-inside a.nav-link:hover {
  color: #000;
  text-decoration: none;
  background-color: rgba(178, 178, 178, 0.5) !important;
}
@media (min-width: 768px) {
  nav#nav-main ul li.menu-item .menu-item-inside a.nav-link:hover + .dropdown-menu {
    display: block;
  }
}
@media (max-width: 767.98px) {
  nav#nav-main ul li.menu-item .menu-item-inside a.nav-link:focus + .dropdown-menu {
    display: block;
  }
}
nav#nav-main ul li.menu-item .menu-item-inside a.nav-link:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.25);
}
nav#nav-main ul li.menu-item .menu-item-inside:hover a.nav-link {
  background-color: rgba(178, 178, 178, 0.25);
}
nav#nav-main ul li.menu-item.menu-item-has-children a.nav-link:after {
  content: "";
  display: inline-block;
  float: right;
  border: solid rgba(0, 0, 0, 0.2);
  border-width: 0 3px 3px 0;
  transform: rotate(-45deg);
  padding: 3px;
  margin-top: 0.45rem;
}
nav#nav-main ul li.menu-item .dropdown-menu {
  display: none;
  position: absolute;
  left: 24rem;
}
nav#nav-main ul li.menu-item .dropdown-menu:hover {
  display: block;
}
nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside {
  position: relative;
  top: -3rem;
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}
nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside a.dropdown-item {
  display: block;
  padding: 0.75rem 2rem 0.75rem 3rem;
  color: #333333;
  font-family: "EB Garamond", serif;
  font-size: 1rem;
  width: 22rem;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}
nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside a.dropdown-item.current {
  color: #000;
  background-color: rgba(204, 204, 204, 0.25);
  font-weight: bold;
}
nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside a.dropdown-item:hover {
  color: #000;
  text-decoration: none;
  background-color: rgba(178, 178, 178, 0.5);
}
nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside a.dropdown-item:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 117, 155, 0.25);
}
@media (min-width: 768px) {
  nav#nav-main ul li.menu-item .dropdown-menu .dropdown-menu-inside a.dropdown-item.dropdown-lead {
    display: none;
  }
}
nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link:not([href]), nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link[href="javascript:;"] {
  cursor: pointer;
}
nav#nav-main form.form-row {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 767.98px) {
  #top-bar.menu-show + div + div + div + #content-overlay button.close {
    display: none;
  }
  #top-bar.menu-show #misc-btns .subscribe-btn {
    width: 0 !important;
    border-width: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #top-bar .menu {
    position: static;
  }
  nav#nav-main {
    left: 0;
    right: 0;
    top: 67px;
    overflow-y: auto;
    position: fixed;
    bottom: 0;
  }
  body.admin-bar nav#nav-main {
    top: 113px;
  }
  nav#nav-main ul li.menu-item.menu-item-has-children a.nav-link:after {
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }
  nav#nav-main ul li.menu-item.menu-item-has-children a.nav-link:focus:after {
    transform: rotate(225deg);
  }
  nav#nav-main ul li.menu-item .menu-item-inside a.nav-link {
    width: auto;
    padding: 1rem 2rem;
  }
  nav#nav-main ul li.menu-item .menu-item-inside .dropdown-menu {
    position: static;
  }
  nav#nav-main ul li.menu-item .menu-item-inside .dropdown-menu .dropdown-menu-inside {
    position: static;
    box-shadow: none;
    padding-bottom: 1rem;
  }
  nav#nav-main ul li.menu-item .menu-item-inside .dropdown-menu .dropdown-menu-inside a.dropdown-item {
    width: auto;
  }
}
@media (min-width: 768px) {
  nav#nav-main.menu-condense {
    left: 0;
    right: 0;
    top: 67px;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    max-width: 400px;
  }
  body.admin-bar nav#nav-main.menu-condense {
    top: 96px;
  }
  nav#nav-main.menu-condense ul li.menu-item.menu-item-has-children a.nav-link:after {
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }
  nav#nav-main.menu-condense ul li.menu-item.menu-item-has-children a.nav-link:focus:after {
    transform: rotate(225deg);
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link {
    width: auto;
    padding: 1rem 2rem;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link:focus {
    color: #000;
    text-decoration: none;
    background-color: rgba(178, 178, 178, 0.5) !important;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link:focus + .dropdown-menu {
    display: block;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside a.nav-link:hover:not(:focus) + .dropdown-menu {
    display: none;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside .dropdown-menu {
    position: static;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside .dropdown-menu .dropdown-menu-inside {
    position: static;
    box-shadow: none;
    padding-bottom: 1rem;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside .dropdown-menu .dropdown-menu-inside a.dropdown-item {
    width: auto;
  }
  nav#nav-main.menu-condense ul li.menu-item .menu-item-inside .dropdown-menu .dropdown-menu-inside a.dropdown-item.dropdown-lead {
    display: block;
  }
}
#search-action-form {
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease;
  position: fixed;
  left: 0;
  right: 0;
  top: 18rem;
}
@media (max-width: 991.98px) {
  #search-action-form {
    top: 12rem;
  }
}
#search-action-form.search-show {
  visibility: visible;
  opacity: 1;
}
#search-action-form input[type=text], #search-action-form button {
  height: 100%;
}
#search-action-form input[type=text] {
  padding: 2.5rem 1.5rem;
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  transition: background-color 0.2s ease;
}
#search-action-form input[type=text]:invalid {
  background-color: rgb(229.5, 229.5, 229.5);
}
#search-action-form input[type=text]:hover, #search-action-form input[type=text]:focus {
  background-color: #fff;
}
#search-action-form button img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 600px) {
  body.admin-bar #wpadminbar {
    position: fixed;
  }
  body.admin-bar nav#nav-main {
    top: 113px;
  }
  body.admin-bar #top-bar.menu-show {
    top: 46px;
  }
}
@media (min-width: 768px) and (max-width: 782px) {
  body.admin-bar nav#nav-main.menu-condense {
    top: 112px;
  }
}
.font-headline {
  font-family: "Playfair Display", serif !important;
}

.font-sans {
  font-family: "Source Sans Pro", sans-serif !important;
}

.font-serif {
  font-family: "EB Garamond", serif !important;
}

::selection {
  background-color: #7AA2BC;
  color: #fff;
}

.post-type-page h1 {
  margin-bottom: 2rem;
}

.breadcrumbs {
  padding-left: 0;
  list-style: none;
  display: inline-block;
  margin-bottom: 3rem;
  padding: 0.5rem 1rem;
  background-color: #f8f8f8;
}
.breadcrumbs li {
  display: inline-block;
  margin-bottom: 0 !important;
}
.breadcrumbs li:not(:last-child) {
  margin-right: 0.5rem;
}
.breadcrumbs li:not(:last-child):after {
  content: "";
  display: inline-block;
  background-image: url("/wp-content/themes/columbian2/static/img/icons/chevron-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 7px;
  height: 10px;
  opacity: 0.25;
  margin-left: 0.5rem;
}

.excerpt {
  font-family: "EB Garamond", serif;
}

a:hover .excerpt {
  text-decoration: none;
}

.story .story-attributes .badge {
  font-size: 85%;
  vertical-align: 1px;
  padding: 4px;
  line-height: 0.7;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.flip {
  transform: rotate(180deg);
}

time {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  color: #9C9C9C;
  font-size: 11px;
}
time.recent {
  color: #de0000;
}

section.related-stories .details {
  line-height: 1;
}
section.related-stories .details .category {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  color: #666666;
  font-size: 11px;
}

.module-heading {
  font-family: "Playfair Display", serif;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-bottom: 1.5rem;
}
.module-heading.module-heading-lg {
  font-size: 30px;
  letter-spacing: 6px;
}
.module-heading a {
  color: #000;
}

.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.33);
  text-shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);
}

.bg-columbian a,
.bg-dark a {
  color: #fff;
}

.bg-insignia {
  background-image: url(/wp-content/themes/columbian2/static/img/columbian-insignia-opaque-new.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% 35px;
}

.bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 991.98px) {
  .bg-cover {
    min-height: 150px;
  }
}
@media (min-width: 992px) {
  .bg-cover.thumbnail {
    min-height: 224px;
  }
}

.flex-columns {
  flex-direction: column;
}

.card p {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.03rem;
}
.card label {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.title-lined {
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin: auto auto 3rem;
}
.title-lined.small {
  font-size: 18px;
  letter-spacing: 13px;
  margin-bottom: 3rem;
  color: #4C4C4C;
}
.title-lined.small span, .title-lined.small a {
  padding-left: calc(1.5rem + 13px);
  padding-right: 1.5rem;
}
.title-lined a.bg-light:hover, .title-lined a.bg-light:focus {
  background-color: #f8f8f8 !important;
}
@media (min-width: 768px) {
  .title-lined {
    margin: 1rem auto 3.5rem;
    line-height: 0;
    border-bottom: 1px solid #B2B2B2;
  }
  .title-lined.small {
    border-color: #CCCCCC;
  }
  .title-lined span, .title-lined a {
    background-color: #fff;
    padding-left: calc(2rem + 6px);
    padding-right: 2rem;
  }
}
@media (min-width: 992px) {
  .title-lined {
    width: 75%;
  }
}

.letter-spacing {
  letter-spacing: 6px;
}

input[type=text].input-dark {
  background-color: #B2B2B2;
  border: none;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
  transition: 0.15s ease-in-out;
}
input[type=text].input-dark::placeholder {
  color: #f8f8f8;
}
input[type=text].input-dark:hover, input[type=text].input-dark:focus {
  background-color: #9C9C9C;
}

.grecaptcha-badge {
  display: none !important;
}

body.author .contact-details {
  font-size: 14px;
}
body.author .contact-details a {
  padding: 0.15rem;
}

.accordion .card-header .btn-link {
  white-space: normal;
  padding-right: 2rem;
  padding-left: 0;
}
.accordion .card-header .btn-link:hover {
  color: inherit;
}
.accordion .card-header .btn-link:before, .accordion .card-header .btn-link:after {
  position: absolute;
  content: "";
  display: block;
  background-color: #666666;
  pointer-events: none;
}
.accordion .card-header .btn-link:before {
  width: 20px;
  height: 4px;
  top: 33px;
  right: 20px;
}
.accordion .card-header .btn-link:after {
  transition: 0.3s ease-in-out;
  width: 4px;
  right: 28px;
  height: 4px;
  top: 33px;
}
.accordion .card-header .btn-link.collapsed:after {
  height: 20px;
  top: 25px;
}
.accordion .card.alert-warning .card-header .btn-link:before, .accordion .card.alert-warning .card-header .btn-link:after {
  background-color: #7f781c;
}

.pdf-link {
  position: relative;
  overflow: hidden;
  transition: filter 0.15s ease;
  display: inline-block;
}
.pdf-link:hover {
  filter: saturate(0);
}
.pdf-link:before {
  content: "View PDF";
  position: absolute;
  font-family: "Playfair Display", serif;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  transition: 0.15s ease;
  z-index: 1;
  height: 9rem;
  width: 9rem;
  line-height: 11rem;
  text-align: center;
  border-radius: 100%;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/pdf-white.svg);
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-size: 28px;
  border: 4px solid rgba(0, 0, 0, 0.5);
  opacity: 0;
  box-shadow: 0 0 20px #fff, 0 0 20px #fff;
}
.pdf-link:hover:before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.row.row-ad {
  flex-wrap: nowrap;
}
.row.row-ad .col-ad {
  width: auto;
}
.row.row-ad .col-ad:last-child {
  flex: 0 0 auto;
}

body.media-viewer-open .tooltip {
  z-index: 10000;
}
body.media-viewer-open .tooltip .tooltip-inner {
  background-color: #fff;
  color: #000;
}
body.media-viewer-open .tooltip .arrow::before {
  border-top-color: #fff;
}

.wp-polls {
  transition: opacity 0.2s ease;
}
.wp-polls[style*=opacity]:not([style*="opacity: 1"]) {
  opacity: 0.5 !important;
}
.wp-polls .progress {
  animation: progressBar 1s ease-in-out;
}
.wp-polls .progress .progress-bar {
  font-size: 100%;
  padding: 0 5px;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.wp-polls-loading {
  height: auto;
  line-height: 1;
  background-color: #39759b;
  padding: 0.75rem;
  color: #fff;
  margin-top: 1rem;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -4.5rem;
  width: 9rem;
}
.wp-polls-loading:before {
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  font-size: 70%;
  margin-right: 0.4rem;
}
.wp-polls-loading img.wp-polls-image {
  display: none;
  /*filter: saturate(12) hue-rotate(160deg);
  margin-right: 0.1rem;
  height: 11px;
  width: 11px;
  margin-top: -3px;*/
}

.subcategory-story-list h3 {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 90%;
}
.subcategory-story-list .badge {
  font-size: 0.75rem;
  font-family: "Source Sans Pro", sans-serif;
  vertical-align: 3px;
}

.alert-breaking {
  background-color: #de0000;
  font-size: 104%;
}
.alert-breaking, .alert-breaking a {
  color: #fff;
}
.alert-breaking .close {
  text-shadow: none;
  color: #fff;
  outline-color: rgb(255, 145.5, 145.5);
  transition: opacity 0.15s ease-in-out;
}
.alert-breaking .close:not(:disabled):not(.disabled):focus, .alert-breaking .close:not(:disabled):not(.disabled):hover {
  color: #fff;
  opacity: 1;
}
.alert-breaking.alert-traffic {
  background-color: #4C4C4C;
}
.alert-breaking.alert-traffic, .alert-breaking.alert-traffic a {
  color: #fff;
}
.alert-breaking.alert-traffic .close {
  outline-color: #999;
}
.alert-breaking.alert-school {
  background-color: #ffdf00;
}
.alert-breaking.alert-school, .alert-breaking.alert-school a {
  color: #000;
}
.alert-breaking.alert-school .close {
  color: #000;
  outline-color: rgb(127.5, 111.5, 0);
}
.alert-breaking.alert-school .close:not(:disabled):not(.disabled):focus, .alert-breaking.alert-school .close:not(:disabled):not(.disabled):hover {
  color: #000;
}
.alert-breaking.alert-school .border-white {
  border-color: #000 !important;
}
.alert-breaking.alert-cta {
  background-color: rgb(215.4, 227.4, 235);
  border: none;
  color: rgb(29.64, 60.84, 80.6);
}
.alert-breaking.alert-cta .alert-link {
  color: rgb(29.64, 60.84, 80.6);
}
.alert-breaking.alert-cta .close {
  color: rgb(29.64, 60.84, 80.6);
  outline-color: #39759b;
}
.alert-breaking.alert-cta .close:not(:disabled):not(.disabled):focus, .alert-breaking.alert-cta .close:not(:disabled):not(.disabled):hover {
  color: rgb(29.64, 60.84, 80.6);
}

#scroll-to-top-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 1px;
}
#scroll-to-top-btn-container #scroll-to-top-btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0.75;
  visibility: visible;
  transform: rotate(270deg);
  width: 2.8rem;
  height: 2.8rem;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/arrow-right-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  transition: 0.2s ease;
  z-index: 9996;
}
#scroll-to-top-btn-container #scroll-to-top-btn:hover {
  opacity: 1;
}
#scroll-to-top-btn-container.hide #scroll-to-top-btn {
  opacity: 0;
  visibility: hidden;
}

.ep-highlight {
  font-style: normal !important;
  font-weight: bold;
  padding: 0.1em 0.2em;
  color: #000;
  background-color: rgba(122, 162, 188, 0.1) !important;
}

.premium-content-label {
  display: none;
}
.premium-content-label.unlocked {
  display: inline-block;
}
.premium-content-label:after {
  content: url("/wp-content/themes/columbian2/static/img/icons/locked-white.svg");
  width: 9px;
  height: 9px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: -1px;
}
.premium-content-label.unlocked:after {
  content: url("/wp-content/themes/columbian2/static/img/icons/unlocked-white.svg");
  width: 11px;
  height: 12px;
  vertical-align: 0px;
}

.featured-media figure.figure {
  max-width: 446px;
}

#ad-homepage-jumbo-billboard, #ad-default-jumbo-billboard {
  display: none;
}

[data-aa-adunit] {
  text-align: center;
}

[data-aa-adunit="/2444258/v3_default_masthead_billboard"]:not(:empty),
[data-aa-adunit="/2444258/v3_default_full_width_above_footer"]:not(:empty),
[data-aa-adunit="/2444258/v3_instory_full_width_above_footer"]:not(:empty),
[data-aa-adunit="/2444258/v3_homepage_full_width_d"]:not(:empty) {
  padding: 1.5rem 0;
  background-color: #f8f8f8;
}

/*.subscribe-btn {
	&.variant-1 {
		// brand blues gradient
		background: linear-gradient( 195deg, $columbian, $columbian-light );
		background-size: 100%;
		transition: background-size 0.2s ease;
		border: none;
		// padding: 0.6rem 0.9rem;

		&:hover {
			background-size: 500%;
		}
	}
	&.variant-2 {
		// vivid red
		$vivid-red: #cf2e2e;
		background-color: $vivid-red;
		border-color: $vivid-red;

		&:hover {
			background-color: darken($vivid-red, 7.5%);
			border-color: darken($vivid-red, 10%);
		}
	}
	&.variant-3 {
		// purple
		$custom-purple: #6610f2;
		background-color: $custom-purple;
		border-color: $custom-purple;

		&:hover {
			background-color: darken($custom-purple, 7.5%);
			border-color: darken($custom-purple, 10%);
		}
	}
	&.variant-4 {
		background: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
		background-size: 100%;
		transition: background-size 0.2s ease;
		border: none;

		&:hover {
			background-size: 500%;
		}
	}
}*/
#breaking-news-takeover {
  background-color: black;
}
#breaking-news-takeover .label {
  line-height: 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  #breaking-news-takeover .container {
    max-width: 100%;
  }
}
#breaking-news-takeover .related-md-only {
  margin-left: -15px;
  margin-right: -15px;
}

.top-stories .large-feature .story-title {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  line-height: 1.3;
}
.top-stories .large-feature .excerpt {
  color: #fff;
  font-size: 22px;
  line-height: 28px;
}
.top-stories .large-feature .excerpt img {
  height: 17px;
  width: 17px;
  vertical-align: -1px;
}
.top-stories .large-feature .col-xl-6 {
  flex-basis: auto;
}
.top-stories .secondary-features .story-title {
  font-family: "Playfair Display", serif;
  font-size: 20px;
  line-height: 27px;
}
@media (min-width: 768px) {
  .top-stories .secondary-features .story-title {
    font-weight: bold;
  }
}
.top-stories .secondary-features .story-title a {
  color: #4C4C4C;
}
.top-stories .secondary-features .excerpt {
  font-size: 17px;
  line-height: 22px;
  color: #666666;
  display: inline;
}
.top-stories .secondary-features dl.details {
  float: right;
  margin-bottom: 0;
}
.top-stories .secondary-features dl.details dd {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 0;
  float: left;
  color: #B2B2B2;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  margin-top: 7px;
}
@media (max-width: 767.98px) {
  .top-stories .secondary-features dl.details dd {
    line-height: 12px;
  }
}
.top-stories .secondary-features dl.details dd.recent {
  color: #de0000;
}
.top-stories .secondary-features dl.details dd:not(:last-child) {
  padding-right: 0.25rem;
  margin-right: 0.25rem;
  border-right: 1px solid #B2B2B2;
}
.top-stories .secondary-features dl.details dd.premium-content-label:after {
  content: url("/wp-content/themes/columbian2/static/img/icons/locked.svg");
  vertical-align: 0;
  opacity: 0.35;
  margin-left: 4px;
}
.top-stories .secondary-features dl.details dd.premium-content-label.unlocked:after {
  content: url("/wp-content/themes/columbian2/static/img/icons/unlocked.svg");
}
.top-stories .secondary-features .sponsored {
  background-color: rgba(122, 162, 188, 0.15);
}
.top-stories .secondary-features .sponsored .story-title a {
  color: rgb(50.1438679245, 102.9268867925, 136.3561320755);
}

.highlighted-stories .module-heading {
  margin-bottom: 2.25rem;
}
.highlighted-stories .story-title {
  font-family: "EB Garamond", serif;
  font-size: 19px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 1.5rem;
}
.highlighted-stories .story-title a {
  color: #666666;
}
.highlighted-stories .story-title a .badge {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 12px;
  vertical-align: 3px;
}
.highlighted-stories .story-title time {
  font-family: "Source Sans Pro", sans-serif;
  display: inline-block;
}
.highlighted-stories .story-title:last-child {
  margin-bottom: 0;
}
.highlighted-stories .center-highlight .story-title {
  font-family: "Playfair Display", serif;
  font-size: 32px;
  line-height: 43px;
  color: #000;
  margin-bottom: 1rem;
}
.highlighted-stories .center-highlight .story-title img.has-video-icon {
  vertical-align: 5px !important;
  margin-left: 0.75rem !important;
}
.highlighted-stories .center-highlight .story-title .badge {
  font-size: 0.75rem;
  font-family: "Source Sans Pro", sans-serif;
  vertical-align: 8px;
}
.highlighted-stories .center-highlight .excerpt {
  font-size: 21px;
  line-height: 30px;
  color: #666666;
}

.story-group .story-title {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 18px;
}
.story-group .story-title:last-child {
  margin-bottom: 0;
}
.story-group .story-title.lead {
  font-weight: bold;
  margin-bottom: 9px;
}
.story-group .story-title .badge {
  font-size: 0.75rem;
  font-family: "Source Sans Pro", sans-serif;
  vertical-align: 3px;
}
.story-group a {
  color: #000;
}
.story-group .excerpt {
  font-size: 16px;
  line-height: 22px;
}
.story-group .section-more-link {
  display: block;
  font-size: 80%;
  font-family: "Source Sans Pro", sans-serif;
  text-align: right;
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  margin-top: auto;
  transition: background-color 0.2s ease;
}
.story-group .section-more-link img {
  width: 6px;
  height: 8px;
}

#weather-widget-full {
  background-color: rgba(57, 117, 155, 0.06);
  font-family: "Source Sans Pro", sans-serif;
  text-decoration: none;
  color: #000;
  transition: background-color 0.2s ease;
}
#weather-widget-full:hover {
  background-color: rgba(57, 117, 155, 0.12);
}
#weather-widget-full .current {
  font-size: 15px;
  line-height: 27px;
}
#weather-widget-full .current .temp {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  line-height: 48px;
}
#weather-widget-full .current .temp sup {
  top: -1.8em;
  left: 0.15em;
  font-size: 12px;
  color: #39759b;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
}
#weather-widget-full .current .status {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0.75rem;
}
#weather-widget-full .current ul li img {
  height: 23px;
}
#weather-widget-full .next-two-days {
  font-size: 14px;
  line-height: 18px;
}
#weather-widget-full .next-two-days .day {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 8px;
}
#weather-widget-full .value {
  color: #39759b;
  font-weight: bold;
}

.sponsored-content .story-title {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 18px;
}

#newsletters-signup-cta h4 {
  font-size: 1.4rem;
}
#newsletters-signup-cta .description {
  font-size: 14px;
  line-height: 20px;
}
#newsletters-signup-cta input[type=email] {
  min-width: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#events-widget {
  font-family: "Source Sans Pro", sans-serif;
}
#events-widget .row {
  margin-bottom: 1.25rem;
}
#events-widget .row:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1439.98px) {
  #events-widget .row:nth-child(4) {
    display: none;
  }
}
#events-widget .month {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #666666;
}
#events-widget .day {
  text-align: center;
  font-weight: bold;
  font-size: 27px;
  color: #39759b;
  line-height: 20px;
}
#events-widget .event-title {
  /* font-size: 18px;
  line-height: 25px; */
}
#events-widget time {
  font-size: 15px;
}

.traffic #travel-time {
  transition: background 0.2s ease;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right-white.svg);
  background-repeat: no-repeat;
  background-position: 110% 50%;
  background-size: 1rem;
  background-blend-mode: soft-light;
}
.traffic #travel-time:hover {
  background-position-x: calc(100% - 1rem);
}
.traffic .btn {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  /* &.btn-dark {
  	background-color: rgba( $gray-800, 0.5 );
  } */
}
.traffic .btn img.btn-icon {
  height: 14px;
  margin-right: 8px;
}
.traffic .traffic-btns {
  line-height: 2.5;
}
.traffic .traffic-map a {
  overflow: hidden;
  display: block;
}
.traffic .traffic-map a img {
  max-width: 630px;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 250px;
}
.traffic .live .label {
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  border-bottom-right-radius: 0.5rem;
  letter-spacing: 1px;
  padding: 0.5rem 1.75rem 0.5rem 1.5rem;
  pointer-events: none;
}
.traffic .live .label:before {
  content: " ";
  background-color: #ff1700;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  margin-right: 7px;
}
.traffic .live .snapshot {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.todays-front-page .preview {
  height: 300px;
  padding: 1.25rem 4rem 0 2rem;
  overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1439.98px) {
  .todays-front-page .preview {
    height: 174px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .todays-front-page .preview {
    height: 500px;
    padding-left: 4rem;
  }
}
.todays-front-page .preview img {
  transform: rotate(10deg);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
}

.special-projects .see-also .title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 6px;
}
.special-projects .see-also .story-group {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  line-height: 24px;
}
.special-projects .see-also .story-group a {
  color: #fff;
}

.wp-polls {
  font-family: "Source Sans Pro", sans-serif;
}
.wp-polls input.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.wp-polls .progress {
  padding: 2px;
  background-color: #9C9C9C;
}
.wp-polls .progress .progress-bar {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.wp-polls .wp-polls-loading {
  margin-top: 1rem;
}
.wp-polls .wp-polls-loading img {
  margin-right: 0.2rem;
}

.columnists .mugshot {
  border-right: 3px solid #7AA2BC;
  border-bottom: 3px solid #7AA2BC;
  width: 75%;
  transition: 0.2s ease;
}
.columnists a:hover .mugshot {
  box-shadow: 2px 0px 0 3px rgba(122, 162, 188, 0.2);
}

.home .subscribe-cta {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.home .subscribe-cta p {
  font-family: "Playfair Display", serif;
  font-size: 1.05rem;
  line-height: 24px;
}

#google_ads_iframe_\/2444258\/v3_homepage_fixed_bottom_0__container__ + a {
  font-size: 0 !important;
  padding: 0 !important;
  height: 0;
}
#google_ads_iframe_\/2444258\/v3_homepage_fixed_bottom_0__container__ + a:before {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="%23fff" width="12" height="12" viewBox="0 0 16 16"><line x1="2.5" y1="13.5" x2="13.5" y2="2.5" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.25"/><line x1="13.5" y1="13.5" x2="2.5" y2="2.5" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.25"/></svg>');
  width: 12px;
  height: 12px;
  display: block;
  background-color: #666666;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  border-radius: 100%;
  transition: background-color 0.2s ease;
  margin-top: -0.1rem;
  margin-right: -0.55rem;
}
#google_ads_iframe_\/2444258\/v3_homepage_fixed_bottom_0__container__ + a:hover:before {
  background-color: rgb(183.75, 0, 0);
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .special-2col-bg {
    background-color: #f8f8f8;
    padding: 1.5rem 2rem;
  }
  .special-2col-bg .excerpt {
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .top-stories .large-feature {
    padding-top: 6rem;
  }
}
@media (max-width: 767.98px) {
  .excerpt {
    display: none !important;
  }
}
body.single article header.article-header {
  /* .headline {
  	font-family: $font-family-headline;
  	font-size: 39px;
  } */
}
body.single article header.article-header h1 .badge {
  font-size: 0.75rem;
  vertical-align: 1.1rem;
  font-family: "Source Sans Pro", sans-serif;
  transition: 0.2s ease;
}
body.single article header.article-header .subhead {
  font-family: "EB Garamond", serif;
  font-size: 26px;
}
body.single article header.article-header .details {
  color: #9C9C9C;
  line-height: 1.7;
  font-size: 85%;
}
body.single article header.article-header .details .indent {
  margin-left: 1.055rem;
}
body.single article header.article-header .details a {
  color: #666666;
}
body.single article header.article-header .details a.crosscut-link {
  color: #9C9C9C;
}
body.single article header.article-header .article-slider figure, body.single article header.article-header .featured-image figure {
  display: table;
  margin: 0 auto;
}
body.single article header.article-header .article-slider figure img, body.single article header.article-header .featured-image figure img {
  max-height: 80vh;
}
body.single article header.article-header .article-slider figure figcaption, body.single article header.article-header .featured-image figure figcaption {
  display: table-caption;
  caption-side: bottom;
}
body.single article .share {
  font-size: 20px;
  font-weight: bold;
}
body.single article .share a {
  padding: 0.25rem 0.75rem;
  line-height: 1;
}
body.single article .share a img {
  width: 22px;
  height: 22px;
}
body.single article .instaread-audio-player {
  margin: 0 auto !important;
}
body.single article section.article-content {
  font-family: "EB Garamond", serif;
  font-size: 22px;
  line-height: 1.7;
  clear: both;
}
@media (max-width: 767.98px) {
  body.single article section.article-content {
    line-height: 1.5;
  }
}
body.single article section.article-content + section.article-content {
  margin-top: 1.75rem;
}
body.single article section.article-content p {
  margin-bottom: 1.75rem;
}
body.single article section.article-content ul, body.single article section.article-content ol {
  margin-bottom: 2.75rem;
}
body.single article section.article-content ul li:not(:last-child), body.single article section.article-content ol li:not(:last-child) {
  margin-bottom: 1rem;
}
body.single article section.article-content p, body.single article section.article-content ul, body.single article section.article-content ol {
  color: #333333;
}
body.single article section.article-content p:last-child, body.single article section.article-content ul:last-child, body.single article section.article-content ol:last-child {
  margin-bottom: 0;
}
body.single article section.article-content pre {
  font-size: 1.1rem;
}
body.single article section.article-content h1, body.single article section.article-content h2, body.single article section.article-content h3, body.single article section.article-content h4 {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
}
body.single article section.article-content h5, body.single article section.article-content h6 {
  margin-top: 3.5rem;
  margin-bottom: 1.75rem;
}
body.single article section.article-content h1 + h1 {
  margin-top: 0;
}
body.single article section.article-content h1 + h2 {
  margin-top: 0;
}
body.single article section.article-content h1 + h3 {
  margin-top: 0;
}
body.single article section.article-content h1 + h4 {
  margin-top: 0;
}
body.single article section.article-content h1 + h5 {
  margin-top: 0;
}
body.single article section.article-content h1 + h6 {
  margin-top: 0;
}
body.single article section.article-content h2 + h2 {
  margin-top: 0;
}
body.single article section.article-content h2 + h1 {
  margin-top: 0;
}
body.single article section.article-content h2 + h3 {
  margin-top: 0;
}
body.single article section.article-content h2 + h4 {
  margin-top: 0;
}
body.single article section.article-content h2 + h5 {
  margin-top: 0;
}
body.single article section.article-content h2 + h6 {
  margin-top: 0;
}
body.single article section.article-content h3 + h3 {
  margin-top: 0;
}
body.single article section.article-content h3 + h1 {
  margin-top: 0;
}
body.single article section.article-content h3 + h2 {
  margin-top: 0;
}
body.single article section.article-content h3 + h4 {
  margin-top: 0;
}
body.single article section.article-content h3 + h5 {
  margin-top: 0;
}
body.single article section.article-content h3 + h6 {
  margin-top: 0;
}
body.single article section.article-content h4 + h4 {
  margin-top: 0;
}
body.single article section.article-content h4 + h1 {
  margin-top: 0;
}
body.single article section.article-content h4 + h2 {
  margin-top: 0;
}
body.single article section.article-content h4 + h3 {
  margin-top: 0;
}
body.single article section.article-content h4 + h5 {
  margin-top: 0;
}
body.single article section.article-content h4 + h6 {
  margin-top: 0;
}
body.single article section.article-content h5 + h5 {
  margin-top: 0;
}
body.single article section.article-content h5 + h1 {
  margin-top: 0;
}
body.single article section.article-content h5 + h2 {
  margin-top: 0;
}
body.single article section.article-content h5 + h3 {
  margin-top: 0;
}
body.single article section.article-content h5 + h4 {
  margin-top: 0;
}
body.single article section.article-content h5 + h6 {
  margin-top: 0;
}
body.single article section.article-content h6 + h6 {
  margin-top: 0;
}
body.single article section.article-content h6 + h1 {
  margin-top: 0;
}
body.single article section.article-content h6 + h2 {
  margin-top: 0;
}
body.single article section.article-content h6 + h3 {
  margin-top: 0;
}
body.single article section.article-content h6 + h4 {
  margin-top: 0;
}
body.single article section.article-content h6 + h5 {
  margin-top: 0;
}
body.single article section.article-content h1, body.single article section.article-content h2, body.single article section.article-content h3, body.single article section.article-content h4, body.single article section.article-content h5, body.single article section.article-content h6 {
  font-weight: bold;
}
body.single article section.article-content.has-sidebar-content {
  margin-bottom: 1.75rem;
}
body.single article section.article-content .card h1, body.single article section.article-content .card h2, body.single article section.article-content .card h3, body.single article section.article-content .card h4, body.single article section.article-content .card h5, body.single article section.article-content .card h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}
.newsletter-cta .cta-label {
  font-size: 20px;
  line-height: 1.5;
}
.newsletter-cta .cta-form {
  font-family: "Source Sans Pro", sans-serif;
  color: #666666;
  font-size: 1rem;
  line-height: 1.5;
}
.newsletter-cta .cta-form form {
  margin-bottom: 1.5rem;
}

body.single article .article-inline {
  clear: both;
}
body.single article .article-inline iframe {
  transition: 0.2s ease;
  display: block;
  max-height: 80vh;
  margin: 0 auto;
}
body.single article .article-inline iframe:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
body.single article .article-inline:empty {
  margin: 0 !important;
  height: 1.75rem;
}
body.single article .article-inline.twitter.twitter-single .twitter-tweet {
  margin: 0 auto;
}
body.single article .article-inline.tableau iframe, body.single article .article-inline.tableau .tableauPlaceholder {
  margin: 0 auto !important;
}
body.single article .article-inline.google-my-maps, body.single article .article-inline.youtube, body.single article .article-inline.vimeo, body.single article .article-inline.facebook-video, body.single article .article-inline.cvtv, body.single article .article-inline.twitter, body.single article .article-inline.custom-code {
  text-align: center;
}
body.single article .article-inline.other-media iframe, body.single article .article-inline.custom-code iframe, body.single article .article-inline.google-my-maps iframe {
  border: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  body.single article .article-inline.map iframe {
    height: 350px;
  }
}
body.single article .article-inline.clark-asks {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  article section.article-content.has-sidebar-content aside .photo,
  article section.article-content.has-sidebar-content aside .twitter-user,
  article section.article-content.has-sidebar-content aside .clark-asks,
  article section.article-content.has-sidebar-content aside .poll .container {
    max-width: 20rem;
  }
}

body.single article .article-inline.flourish .flourish-credit {
  display: none;
}
body.single article .article-inline.instagram iframe {
  margin: 0 auto !important;
  max-height: none;
}
body.single article .article-inline.poll {
  font-family: "Source Sans Pro", sans-serif;
}
body.single article .article-inline.poll label {
  display: block;
  line-height: inherit;
}
body.single article .article-inline.clark-asks {
  font-family: "Source Sans Pro", sans-serif;
}
body.single article .article-inline.clark-asks h3, body.single article .article-inline.clark-asks h4 {
  margin-top: 0;
}
body.single article .article-inline.survey-monkey .smcx-embed, body.single article .article-inline.survey-monkey .smcx-embed > .smcx-iframe-container {
  max-width: 100%;
}
body.single article .article-inline.tableau .tableauPlaceholder {
  height: auto !important;
}
body.single article .article-inline.trending {
  margin-top: 5.4rem !important;
}
@media (max-width: 767.98px) {
  body.single article .article-inline.trending .bg-cover {
    min-height: auto;
    height: 100%;
  }
}
body.single article .article-inline.twitter.twitter-custom iframe {
  max-width: 1200px !important;
}
@media (max-width: 575.98px) {
  body.single article .article-inline.vimeo iframe, body.single article .article-inline.youtube iframe {
    height: 256px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  body.single article .article-inline.vimeo iframe, body.single article .article-inline.youtube iframe {
    height: 287px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  body.single article .article-inline.vimeo iframe, body.single article .article-inline.youtube iframe {
    height: 388px;
  }
}
@media (min-width: 992px) and (max-width: 1439.98px) {
  body.single article .article-inline.vimeo iframe, body.single article .article-inline.youtube iframe {
    height: 542px;
  }
}
@media (min-width: 1440px) {
  body.single article .article-inline.vimeo iframe, body.single article .article-inline.youtube iframe {
    height: 680px;
  }
}
body.single article .article-inline.facebook-video figure {
  max-width: 500px;
}
body.single article .article-inline.facebook-video .fb-video span {
  height: auto !important;
}
body.single article .article-inline.cvtv figure {
  max-width: calc(640px + 2rem);
}
body.single article .article-inline.cvtv figure iframe {
  max-width: 640px;
}
@media (max-width: 670px) {
  body.single article .article-inline.cvtv figure iframe {
    width: 100%;
    height: 53vw;
  }
}
body.single article .article-inline.audio-clip .green-audio-player .holder .play-pause-btn:hover path {
  fill: rgb(107.7128712871, 126.5, 145.2871287129);
  transition: 0.1s ease;
}
body.single article .article-inline.audio-clip .green-audio-player .slider {
  transition: 0.1s ease;
}
body.single article .article-inline.audio-clip .green-audio-player .slider .gap-progress {
  background: linear-gradient(to left, #39759b, #7AC4F4);
}
body.single article .article-inline.audio-clip .green-audio-player .slider .gap-progress.volume__progress {
  background: linear-gradient(to bottom, #39759b, #7AC4F4);
}
body.single article .article-inline.audio-clip .green-audio-player .slider .gap-progress,
body.single article .article-inline.audio-clip .green-audio-player .slider .gap-progress .pin {
  background-color: #7AC4F4;
}
body.single article .article-inline.audio-clip .green-audio-player .slider:hover {
  background-color: rgb(228.75, 228.75, 228.75);
}
body.single article .article-inline.audio-clip .green-audio-player .volume .volume__button:hover path {
  fill: rgb(107.7128712871, 126.5, 145.2871287129);
}
body.single article .article-inline.audio-clip .green-audio-player .volume .volume__button.open path {
  fill: #39759b;
}
body.single article .article-inline.audio-clip .green-audio-player .volume .volume__button path {
  transition: 0.1s ease;
}
body.single article .article-inline.audio-clip .green-audio-player .slider .gap-progress .pin:hover {
  background-color: rgb(169.1041666667, 216.8958333333, 247.8958333333);
}
body.single article .article-inline.canva .container {
  font-size: 0;
}
body.single article .article-inline.canva .container div {
  font-size: initial;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
@media (min-width: 992px) {
  body.single article .article-inline.canva .container div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 80vh !important;
  }
}
body.single article .article-inline.canva .container div iframe {
  max-height: none;
}
body.single article .article-inline.html-content-box * {
  width: fit-content;
}
body.single article .article-inline.html-content-box h3 {
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin: auto auto 2.25rem;
}
body.single article .article-inline.html-content-box p:last-child {
  margin-bottom: 0;
}
body.single article .article-inline.html-content-box h4, body.single article .article-inline.html-content-box h5, body.single article .article-inline.html-content-box h6 {
  margin-top: 1.75rem;
}
body.single article .article-inline.html-content-box [style*=text-align] {
  width: auto;
}
body.single article .article-inline.html-content-box [style*="text-align: left"], body.single article .article-inline.html-content-box [style*="text-align:left"] {
  width: fit-content;
}
body.single article .article-inline.html-content-box .spread {
  margin: 3rem auto;
}
@media (min-width: 768px) {
  body.single article .article-inline.html-content-box .spread {
    column-count: 2;
    column-gap: 30px;
  }
}
body.single article .article-inline.html-content-box .spread:last-child {
  margin-bottom: 0;
}
body.single article .article-inline.other-media .container * {
  max-width: max-content;
  margin: 5px auto 0;
  text-align: center;
}
body.single article .article-inline.other-media .container .pdf-link {
  display: block;
}
body.single article .article-inline.other-media .container iframe {
  margin: 0 auto;
  display: block;
  max-width: 100%;
}
body.single article .author-box img.mugshot {
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.15);
}
body.single article .author-box .name {
  font-family: "Playfair Display", serif;
  font-size: 28px;
}
body.single article .author-box .name a {
  color: #000;
}
body.single article .author-box .role {
  font-family: "EB Garamond", serif;
  font-size: 17px;
  color: #9C9C9C;
}
body.single article .author-box .contact-items {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.75;
  color: #333333;
}
body.single article .author-box .contact-items a {
  color: #333333;
}
body.single article .author-box .contact-items img {
  max-width: 20px;
  opacity: 0.5;
}
body.single article .author-box .contact-items .email {
  display: flex;
}
body.single article .author-box .contact-items .email a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 0.3s ease-out;
}
body.single article .author-box .contact-items .email a:hover {
  overflow: visible;
  z-index: 1;
  background-color: #fff;
  padding-right: 0.25rem;
}

/* paywall modal */
.paywall-modal {
  display: none;
  position: fixed;
  z-index: 19999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.paywall-modal .modal-content {
  background-color: #fff;
  margin: 10% auto;
  width: 85%;
  text-align: center;
  position: relative;
}
@media (min-width: 768px) {
  .paywall-modal .modal-content {
    width: 60%;
  }
}
.paywall-modal .maybe-later {
  font-size: 16px;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
  margin-top: 20px;
}
.paywall-modal .maybe-later:hover {
  color: #c82333;
}

figure {
  position: relative;
}
figure .enlarge-link {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
figure .enlarge-link:before {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  z-index: 1;
}
figure .enlarge-link.photo:before {
  transform: translate(-50%, -50%);
  transition: background-size 0.2s ease, background-position 0.2s ease, opacity 0.2s ease, filter 0.6s ease;
  height: 100%;
  width: 100%;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/search-white.svg), url(/wp-content/themes/columbian2/static/img/icons/plus-white.svg);
  background-repeat: no-repeat;
  background-position: center, 44% 40%;
  background-size: 60%, 17%;
  opacity: 0;
}
figure .enlarge-link.photo:hover:before {
  background-size: 30%, 8.5%;
  background-position: center, 47% 45%;
  opacity: 0.85;
  filter: drop-shadow(0 0 12px #000);
}
figure .enlarge-link.video:before {
  transform: translate(-50%, -50%);
  transition: 0.2s ease;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  border-style: solid;
  border-width: 45px 0 45px 72px;
  display: block;
  height: 0;
  width: 0;
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.1));
  /* background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="50px" height="60px" viewBox="0 0 50 60"%3E%3Cpolygon fill="%23fff" points="0,0 0,60 50,30 "/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  height: 100%;
  width: 30%;
  opacity: 0.75; */
}
figure .enlarge-link.video:hover:before {
  border-left-color: #fff;
  border-width: 25px 0 25px 40px;
  filter: drop-shadow(8px 8px 15px rgba(0, 0, 0, 0.75));
  left: 40%;
}
figure .enlarge-link.video:after {
  content: "PLAY";
  position: absolute;
  font-weight: bold;
  color: #fff;
  top: 50%;
  left: 60%;
  transform: translate(10%, -50%);
  font-size: 2rem;
  line-height: 1;
  opacity: 0;
  transition: 0.4s ease;
  letter-spacing: 10px;
  pointer-events: none;
}
figure .enlarge-link.video:hover:after {
  opacity: 1;
  left: 50%;
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  letter-spacing: 0;
}
figure .figure-caption {
  color: #7F7F7F;
  font-family: "EB Garamond", serif;
  font-size: 1rem;
  text-align: left;
}
figure .figure-caption .badge {
  vertical-align: 1px;
}
figure.float-right {
  margin-left: 1.5rem;
  margin-bottom: 1.25rem;
}
figure.float-left {
  margin-right: 1.5rem;
  margin-bottom: 1.25rem;
}
figure .total-photos {
  position: absolute;
  background-color: #7AA2BC;
  color: #fff;
  font-weight: bold;
  font-size: 80%;
  pointer-events: none;
  margin-top: 0.75rem;
  padding: 0.15rem 1rem 0;
  right: 0;
  height: 24px;
  transform-style: preserve-3d;
  z-index: 1;
}
figure .total-photos:before {
  content: "";
  position: absolute;
  left: -16px;
  top: 8px;
  border-top: 12px solid #759bb6;
  border-bottom: 12px solid #759bb6;
  border-left: 8px solid transparent;
}
figure .total-photos:after {
  content: "";
  display: block;
  position: absolute;
  left: -0.5rem;
  top: 0.5rem;
  border-color: #769cb5;
  border-right-color: #5e839c;
  width: 0;
  height: 24px;
  border-style: solid;
  border-width: 0.5rem;
  z-index: -1;
  transform: translateZ(-1rem);
}
figure span.enlarge {
  display: block;
  width: 40px;
  height: 40px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/wp-content/themes/columbian2/static/img/icons/enlarge.svg);
  position: absolute;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  z-index: 1;
}
figure img[data-enlarge], figure img[data-gallery], figure img[data-video] {
  cursor: zoom-in;
  transition: 0.2s ease;
}
figure img[data-enlarge]:hover, figure img[data-gallery]:hover, figure img[data-video]:hover {
  filter: brightness(125%);
}
figure img[data-enlarge] + .instruction, figure img[data-gallery] + .instruction, figure img[data-video] + .instruction {
  position: absolute;
  color: #fff;
  padding: 1rem;
  font-family: "Playfair Display", serif;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  width: 100%;
  top: 75%;
}
figure img[data-enlarge] + .instruction:before, figure img[data-gallery] + .instruction:before, figure img[data-video] + .instruction:before {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/open-white.svg);
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: -2px;
}

#col-homes .photo {
  overflow: hidden;
  height: 100%;
}
#col-homes .photo a {
  display: block;
  height: 100%;
  width: 100%;
  min-height: 202px;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
}
#col-homes .photo a:hover {
  transform: scale(1.2);
}
@media (max-width: 991.98px) {
  #col-homes .photo a {
    background-size: cover;
  }
  #col-homes .photo a:hover {
    background-size: cover;
  }
}
@media (max-width: 767.98px) {
  #col-homes .photo a {
    min-height: 125px;
  }
}

.related-stories a {
  color: #000;
}
@media (max-width: 767.98px) {
  .related-stories .related-story:nth-child(odd) .headline {
    background-color: #f8f8f8;
  }
}
@media (max-width: 767.98px) {
  .related-stories .related-story .bg-cover {
    min-height: auto;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .related-stories .related-story .headline {
    height: 100%;
  }
}
@media (min-width: 768px) {
  .related-stories .related-story .headline {
    background-color: #f8f8f8;
  }
}
.related-stories .related-story .headline .highlighted-headline {
  font-family: "Playfair Display", serif;
  font-size: 1.25rem;
  line-height: 1.4;
}
.related-stories .related-story .headline .highlighted-headline .badge {
  font-size: 0.75rem;
  vertical-align: 3px;
}

iframe.flippxp-frame {
  margin-bottom: 6rem !important;
}

/* #dcl_comment_btn:before {
	content: '';
	background-image: url(/wp-content/themes/columbian2/static/img/icons/comments-white.svg);
	margin-right: 0.35rem;
	width: 24px;
	height: 19px;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	vertical-align: -4px;
} */
@media (min-width: 768px) and (max-width: 991.98px) {
  iframe.video-player {
    height: 388px;
  }
}
@media (max-width: 767.98px) {
  iframe.video-player {
    height: 287px;
  }
}

.death-notices-list, .vitals-list {
  font-family: "EB Garamond", serif;
  font-size: 1.1rem;
  line-height: 2;
}

.vitals-list span:not(:first-child) {
  text-transform: lowercase;
}
.vitals-list span:after {
  content: ", ";
}
.vitals-list span:last-child:after {
  content: "";
}
.vitals-list span:last-child:not(:first-child):not(:nth-child(2)):before {
  content: "and ";
}

.bg-twitter {
  background-image: url("/wp-content/themes/columbian2/static/img/icons/social/twitter.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 14% 1.5rem;
  background-blend-mode: luminosity;
}
.bg-twitter + .bg-columbian-faded {
  background-color: #39759b !important;
}
.bg-twitter + .bg-columbian-faded .badge-columbian {
  background-color: #7AA2BC !important;
}

@media (min-width: 768px) {
  .archive h1 {
    font-size: 3.5rem;
  }
}
.archive .story .thumb {
  max-width: 210px;
  flex-basis: 210px;
  padding: 0 15px;
}
.archive .story .details {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 15px;
}
.archive .story .details .mobile-thumb {
  width: 35%;
  max-width: 180px;
}
.archive .story img.has-video-icon {
  vertical-align: 0.25rem !important;
  margin-left: 0.6rem !important;
}

.hero {
  background-image: linear-gradient(to bottom right, rgba(57, 117, 155, 0.75), rgba(122, 162, 188, 0.5)), url(/wp-content/themes/columbian2/static/img/hero/i-5-bridge-wa-or-border-columbia-river.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: exclusion;
  text-shadow: 0rem 0.25rem 1rem #000;
}
.hero h1 {
  text-shadow: 0rem 0.5rem 1.5rem #000;
}

@media (min-width: 768px) {
  .cross-column {
    column-count: 3;
    column-gap: 30px;
  }
}

.pink-container {
  background: #fbdce4;
  background: -moz-radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
  background: -webkit-radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
  background: radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbdce4",endColorstr="#e6c1ca",GradientType=1);
}
.pink-container h1, .pink-container .h1, .pink-container .small, .pink-container a {
  color: #af4975;
}
.pink-container h3.title-lined {
  border-color: #e6c1ca;
}
@media (min-width: 768px) {
  .pink-container h3.title-lined span {
    background-color: #fbdce4;
  }
}
.pink-container .btn-light {
  background-color: rgba(255, 241, 245, 0.8);
  border-color: rgba(255, 241, 245, 0.8);
}
.pink-container .btn-light:hover {
  background-color: #fdd4de;
  border-color: #ffe3eb;
  color: #862c53;
}
.pink-container .btn-light.btn-arrow:after {
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='20' viewBox='434 310 14 20'%3E%3Cpath fill='%23d67ca3' d='M437 310l-3 3 8 7-8 7 3 3 11-10-11-10z'/%3E%3C/svg%3E"); /* right arrow with custom matching color */
}

label#authors_label, #authors_chosen {
  transition: opacity 0.2s ease;
}

.btn .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.15em;
  vertical-align: middle;
}

body.page ol li {
  margin-bottom: 1rem;
}
body.page ol li:last-child {
  margin-bottom: 0;
}

.glyphicon-asterisk:before {
  content: "*";
  font-size: 2rem;
  float: right;
  line-height: 2.5rem;
  color: #f00;
  margin-left: 0.3rem;
  line-height: 0;
  margin-top: 1rem;
}

span.wpcf7-not-valid-tip {
  font-size: 90%;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  color: #de0000;
}

span.wpcf7-list-item {
  margin: 0 0 0 2rem;
}
span.wpcf7-list-item.first {
  margin-left: 0;
}
span.wpcf7-list-item input[type=radio] {
  margin-right: 0.4rem;
}

.wpcf7 form .wpcf7-response-output {
  padding: 0.75rem 1.25rem;
  margin: 1.5rem 0 1rem;
  border: 1px solid transparent;
}
.wpcf7 form .wpcf7-response-output:before {
  margin-right: 0.5rem;
}
.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output, .wpcf7 form.failed .wpcf7-response-output, .wpcf7 form.aborted .wpcf7-response-output, .wpcf7 form.spam .wpcf7-response-output {
  color: rgb(115.44, 0, 0);
  background-color: rgb(248.4, 204, 204);
  border-color: rgb(245.76, 183.6, 183.6);
}
.wpcf7 form.invalid .wpcf7-response-output:before, .wpcf7 form.unaccepted .wpcf7-response-output:before, .wpcf7 form.failed .wpcf7-response-output:before, .wpcf7 form.aborted .wpcf7-response-output:before, .wpcf7 form.spam .wpcf7-response-output:before {
  content: "";
  display: inline-block;
  background-image: url("/wp-content/themes/columbian2/static/img/icons/danger.svg");
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  background-size: 22px;
  vertical-align: -5px;
}
.wpcf7 form.sent .wpcf7-response-output {
  color: rgb(20.8, 86.84, 35.88);
  background-color: rgb(212, 237.4, 217.8);
  border-color: rgb(194.8, 230.36, 202.92);
}
.wpcf7 form.sent .wpcf7-response-output:before {
  content: "";
  display: inline-block;
  background-image: url("/wp-content/themes/columbian2/static/img/icons/success.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px;
  vertical-align: -6px;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
@media screen and (max-width: 782.98px) {
  body.admin-bar {
    min-height: calc(100vh - 46px);
  }
}
@media screen and (min-width: 783px) {
  body.admin-bar {
    min-height: calc(100vh - 32px);
  }
}

footer {
  border-top: 1px solid rgba(229, 229, 229, 0.5);
}
footer .footer-menu .footer-column-label {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 9px;
  outline: 0;
}
footer .footer-menu .footer-menu-group {
  line-height: 1.25;
}
footer .footer-menu .footer-menu-group a {
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0.3rem;
}
@media (max-width: 575.98px) {
  footer .footer-menu .footer-column-label {
    border-bottom-color: rgba(122, 196, 244, 0.5) !important;
  }
  footer .footer-menu .footer-column-label:after {
    content: "";
    display: inline-block;
    border: solid rgba(0, 0, 0, 0.2);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    padding: 3px;
    margin-left: 0.4rem;
    vertical-align: 13%;
    transition: border-color 0.5s ease-in-out;
  }
  footer .footer-menu .footer-menu-group {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  footer .footer-menu .footer-menu-group a {
    font-size: 100%;
    line-height: 30px;
  }
  footer .footer-menu .footer-menu-group a:last-child {
    display: inline-block;
    margin-bottom: 1.75rem;
  }
  footer .footer-menu .footer-menu-column:hover > .footer-column-label:after {
    border-color: rgba(0, 0, 0, 0.5);
  }
  footer .footer-menu .footer-menu-column:hover > .footer-menu-group {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
  }
}
footer .footer-logo {
  margin-bottom: 2rem;
}
footer form.search {
  margin-bottom: 2rem;
  /* button { // (the submit button)
  	&:focus {
  		background-color: $columbian-light;
  	}
  } */
}
footer form.search .box {
  display: inline-block;
  height: 40px;
  width: 40px;
}
footer .social a {
  border: 2px solid transparent;
  padding: 0.5rem;
  border-radius: 100%;
  display: inline-flex;
  transition: 0.3s ease;
}
footer .social a img {
  width: 18px;
  height: 18px;
  transition: filter 0.3s ease;
  filter: saturate(0);
}
footer .social a:hover {
  background-color: rgba(122, 162, 188, 0.1);
  border-color: rgba(122, 162, 188, 0.1);
  transform: scale(1.2);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
}
footer .social a:hover img {
  filter: saturate(1);
}
footer.copyright .menu a {
  color: #7AA2BC;
  margin-left: 0.75rem;
  display: inline-block;
}

#ad-default-full-width-above-footer + .mt-auto footer.mt-4, #ad-instory-full-width-above-footer + .mt-auto footer.mt-4 {
  margin-top: 0 !important;
}