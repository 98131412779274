body.single article/* .post-type-post */ {
	header.article-header {
		/* .headline {
			font-family: $font-family-headline;
			font-size: 39px;
		} */
		h1 {
			.badge {
				font-size: 0.75rem;
				vertical-align: 1.1rem;
				font-family: $font-family-sans-serif;
				transition: 0.2s ease;
			}
		}
		.subhead {
			font-family: $font-family-subhead;
			font-size: 26px;
		}
		.details {
			color: $gray-400;
			line-height: 1.7;
			font-size: 85%;

			.indent {
				margin-left: 1.055rem;
			}
			a {
				color: $gray-600;

				&.crosscut-link {
					color: $gray-400;
				}
			}
		}
		.article-slider, .featured-image {
			figure {
				display: table;
				margin: 0 auto;

				img {
					max-height: 80vh;
				}
				figcaption {
					display: table-caption;
					caption-side: bottom;
				}
			}
		}
	}
	.share {
		font-size: 20px;
		font-weight: bold;

		a {
			padding: 0.25rem 0.75rem;
			line-height: 1;

			img {
				width: 22px;
				height: 22px;
			}
		}
	}
	.instaread-audio-player{
		margin: 0 auto !important;
	}
	section.article-content {
		font-family: $font-family-subhead;
		font-size: 22px;
		line-height: 1.7;
		clear: both;

		@include media-breakpoint-down(sm) { line-height: 1.5; }
		& + section.article-content {
			margin-top: 1.75rem;
		}
		p {
			margin-bottom: 1.75rem;
		}
		ul, ol {
			margin-bottom: 2.75rem;

			li:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
		p, ul, ol {
			color: $gray-800;

			&:last-child {
				margin-bottom: 0;
			}
		}
		pre {
			font-size: 1.1rem;
		}
		h1, h2, h3, h4 { // not using h1 or h2 tags in article body, but adding styles for redundancy
			margin-top: 5rem;
			margin-bottom: 2.5rem;
		}
		h5, h6 {
			margin-top: 3.5rem;
			margin-bottom: 1.75rem;
		}
		// kill top margin for <h[1-6]> tags if preceded by another <h[1-6]> tag
		@for $index from 1 through 6 {
			h#{$index} + h#{$index} { margin-top: 0; }
			@for $subindex from 1 through 6 {
			@if $index != $subindex {
				  	h#{$index} + h#{$subindex} { margin-top: 0; }
				}
			}
		}
		h1, h2, h3, h4, h5, h6 {
			font-weight: bold;
		}
		&.has-sidebar-content {
			margin-bottom: 1.75rem;
		}
		.card {
			h1,h2,h3,h4,h5,h6 {
				margin-top: 0;
				margin-bottom: 1rem;
			}
		}
		@at-root .newsletter-cta {
			.cta-label {
				// font-family: $font-family-subhead;
				// color: $gray-600;
				font-size: 20px;
				line-height: 1.5;
			}
			.cta-form {
				font-family: $font-family-sans-serif;
				color: $gray-600;
				font-size: 1rem;
				line-height: 1.5;

				form {
					margin-bottom: 1.5rem;
				}
			}
		}
	}
	.article-inline {
		clear: both;

		iframe {
			transition: 0.2s ease;
			display: block;
			max-height: 80vh;
			margin: 0 auto;

			&:hover {
				box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
			}
		}
		&:empty {
			margin: 0 !important;
			height: 1.75rem;
		}
		&.twitter.twitter-single .twitter-tweet {
			margin: 0 auto;
		}
		&.tableau iframe,
		&.tableau .tableauPlaceholder {
			margin: 0 auto !important;
		}
		&.google-my-maps,
		&.youtube,
		&.vimeo,
		&.facebook-video,
		&.cvtv,
		&.twitter,
		&.custom-code {
			text-align: center;
		}
		&.other-media,
		&.custom-code,
		&.google-my-maps {
			iframe {
				border: 0;
				width: 100%;
			}
		}
		&.map {
			iframe {
				@include media-breakpoint-down(sm) {
					height: 350px;
				}
			}
		}
		&.clark-asks {
			max-width: 30rem;
			margin-left: auto;
			margin-right: auto;
		}
		@at-root article/*.post-type-post*/ section.article-content.has-sidebar-content aside {
			.photo,
			.twitter-user,
			.clark-asks,
			.poll .container {
				@include media-breakpoint-up(lg) {
					max-width: 20rem;
				}
			}
		}
		&.flourish .flourish-credit {
			display: none;
		}
		&.instagram {
			iframe {
				margin: 0 auto !important;
				max-height: none;
			}
		}
		&.poll {
			font-family: $font-family-sans-serif;

			label {
				display: block;
				line-height: inherit;
			}
		}
		&.clark-asks {
			font-family: $font-family-sans-serif;

			h3, h4 {
				margin-top: 0;
			}
		}
		&.survey-monkey .smcx-embed,
		&.survey-monkey .smcx-embed>.smcx-iframe-container {
			max-width: 100%;
		}
		&.tableau .tableauPlaceholder {
			height: auto !important;
		}
		&.trending {
			margin-top: 5.4rem !important;

			.bg-cover {
				@include media-breakpoint-down(sm) {
					min-height: auto;
					height: 100%;
				}
			}
		}
		&.twitter.twitter-custom iframe {
			max-width: 1200px !important;
		}
		&.vimeo, &.youtube {
			iframe {
				@include media-breakpoint-only(xs) { height: 256px; }
				@include media-breakpoint-only(sm) { height: 287px; }
				@include media-breakpoint-only(md) { height: 388px; }
				@include media-breakpoint-only(lg) { height: 542px; }
				@include media-breakpoint-only(xl) { height: 680px; }
			}
		}
		&.facebook-video {
			figure {
				max-width: 500px;
			}
			.fb-video span {
				height: auto !important;
			}
		}
		&.cvtv {
			figure {
				max-width: calc( 640px + 2rem );

				iframe {
					max-width: 640px;

					@media (max-width: 670px) {
						width: 100%;
						height: 53vw;
					}
				}
			}
		}
		&.audio-clip .green-audio-player {
			.holder .play-pause-btn:hover path {
				fill: lighten( #566574, 10% );
				transition: 0.1s ease;
			}
			.slider {
				transition: 0.1s ease;

				.gap-progress {
					background: linear-gradient(to left, $columbian, $columbian-light);

					&.volume__progress {
						background: linear-gradient(to bottom, $columbian, $columbian-light);
					}
				}
				.gap-progress,
				.gap-progress .pin {
					background-color: $columbian-light;
				}
				&:hover {
					background-color: lighten( #d8d8d8, 5% );
				}
			}
			.volume .volume__button {
				&:hover path {
					fill: lighten( #566574, 10% );
				}
				&.open path {
					fill: $columbian;
				}
				path {
					transition: 0.1s ease;
				}
			}
		}
		&.audio-clip .green-audio-player .slider .gap-progress .pin:hover {
			background-color: lighten( $columbian-light, 10% );
		}
		&.canva {
			.container {
				font-size: 0; // to hide link and user pasted from Canva embed

				div {
					font-size: initial;
					margin-top: 0 !important;
					margin-bottom: 0 !important;
					box-shadow: $box-shadow !important;

					@include media-breakpoint-up(lg) {
						padding-top: 0 !important;
						padding-bottom: 0 !important;
						height: 80vh !important;
					}
					iframe {
						max-height: none;
					}
				}
			}
		}
		&.html-content-box {
			* {
				width: fit-content;
			}
			h3 {
				text-align: center;
				font-family: "Playfair Display",serif;
				font-size: 22px;
				text-transform: uppercase;
				letter-spacing: 6px;
				margin: auto auto 2.25rem;
			}
			p:last-child {
				margin-bottom: 0;
			}
			h4, h5, h6 {
				margin-top: 1.75rem;
			}
			[style*="text-align"] {
				width: auto;
			}
			[style*="text-align: left"], [style*="text-align:left"] {
				width: fit-content;
			}
			.spread {
				margin: 3rem auto;

				@include media-breakpoint-up(md) {
					column-count: 2;
					column-gap: 30px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&.other-media .container {
			* {
				max-width: max-content;
				margin: 5px auto 0;
				text-align: center;
			}
			.pdf-link {
				display: block;
			}
			iframe {
				margin: 0 auto;
				display: block;
				max-width: 100%;
			}
		}
	}
	.author-box {
		img.mugshot {
			box-shadow: 3px 5px 15px rgba($black, 0.15);
			// border-radius: 100%;
		}
		.name {
			font-family: $font-family-headline;
			font-size: 28px;
			// margin-bottom: 0.25rem;

			a {
				color: $black;
			}
		}
		.role {
			font-family: $font-family-subhead;
			font-size: 17px;
			color: $gray-400;
			// margin-bottom: .75rem
		}
		.contact-items {
			font-family: $font-family-sans-serif;
			font-size: 14px;
			line-height: 1.75;
			color: $gray-800;
			// padding: 0.5rem 0.75rem;

			a {
				color: $gray-800;
			}
			img {
				max-width: 20px;
				opacity: 0.5;
			}
			.email {
				display: flex;

				a {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					transition: background-color 0.3s ease-out;

					&:hover {
						overflow: visible;
						z-index: 1;
						background-color: #fff;
						padding-right: 0.25rem;
					}
				}
			}
		}
	}
}


/* paywall modal */
.paywall-modal {
	display: none;
	position: fixed;
	z-index: 19999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba($black, 0.5);

	.modal-content {
		background-color: #fff;
		margin: 10% auto;
		width: 85%;
		text-align: center;
		position: relative;

		@include media-breakpoint-up(md) {
			width: 60%;
		}
	}
	.maybe-later {
		font-size: 16px;
		text-decoration: underline;
		color: #000;
		cursor: pointer;
		margin-top: 20px;

		&:hover {
			color: #c82333;
		}
	}
}

figure {
	position: relative;

	.enlarge-link { // zoom icon on hover
		position: relative;
		overflow: hidden;
		display: inline-block;

		&:before {
			content: '';
			position: absolute;
			pointer-events: none;
			top: 50%;
			left: 50%;
			z-index: 1;
		}
		&.photo {
			&:before {
				transform: translate(-50%, -50%);
				transition: background-size 0.2s ease, background-position 0.2s ease, opacity 0.2s ease, filter 0.6s ease;
				height: 100%;
				width: 100%;
				// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle fill='none' stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' cx='8.316' cy='8.316' r='6.035'/%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='M17.188 17.188l-4.526-4.526'/%3E%3C/svg%3E");
				background-image: url(/wp-content/themes/columbian2/static/img/icons/search-white.svg), url(/wp-content/themes/columbian2/static/img/icons/plus-white.svg);
				background-repeat: no-repeat;
				background-position: center, 44% 40%;
				background-size: 60%, 17%;
				opacity: 0;
			}
			&:hover:before {
				background-size: 30%, 8.5%;
				background-position: center, 47% 45%;
				opacity: 0.85;
				filter: drop-shadow(0 0 12px $black);
			}
		}
		&.video {
			&:before {
				transform: translate(-50%, -50%);
				transition: 0.2s ease;

				border-color: transparent transparent transparent rgba($white, 0.75);
				border-style: solid;
				// border-width: 50px 0 50px 80px;
				border-width: 45px 0 45px 72px;
				display: block;
				height: 0;
				width: 0;
				filter: drop-shadow(0 0 12px rgba(0,0,0,0.1));

				/* background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="50px" height="60px" viewBox="0 0 50 60"%3E%3Cpolygon fill="%23fff" points="0,0 0,60 50,30 "/%3E%3C/svg%3E%0A');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 60%;
				height: 100%;
				width: 30%;
				opacity: 0.75; */
			}
			&:hover:before {
				border-left-color: $white;
				border-width: 25px 0 25px 40px;
				filter: drop-shadow(8px 8px 15px rgba($black,0.75));
				left: 40%;
			}
			&:after {
				content: 'PLAY';
				position: absolute;
				font-weight: bold;
				color: $white;
				top: 50%;
				left: 60%;
				transform: translate(10%,-50%);
				font-size: 2rem;
				line-height: 1;
				opacity: 0;
				transition: 0.4s ease;
				letter-spacing: 10px;
				pointer-events: none;
			}
			&:hover:after {
				opacity: 1;
				left: 50%;
				text-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
				letter-spacing: 0;
			}
		}
	}
	.figure-caption {
		color: $gray-500;
		font-family: $font-family-subhead;
		font-size: 1rem;
		text-align: left;

		.badge {
			vertical-align: 1px;
		}
	}
	&.float-right {
		margin-left: 1.5rem;
		margin-bottom: 1.25rem;
	}
	&.float-left {
		margin-right: 1.5rem;
		margin-bottom: 1.25rem;
	}
	.total-photos { // "XX Photos" gallery label
		position: absolute;
		background-color: $columbian-faded;
		color: $white;
		font-weight: bold;
		font-size: 80%;
		pointer-events: none;
		margin-top: 0.75rem;
		padding: 0.15rem 1rem 0;
		right: 0;
		// margin-right: 15px;
		height: 24px;
		transform-style: preserve-3d;
		z-index: 1;

		&:before {
			content: '';
			position: absolute;
			left: -16px;
			top: 8px;
			border-top: 12px solid #759bb6;
			border-bottom: 12px solid #759bb6;
			border-left: 8px solid transparent;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: -0.5rem;
			top: 0.5rem;
			border-color: #769cb5;
			border-right-color: #5e839c;
			width: 0;
			height: 24px;
			border-style: solid;
			border-width: 0.5rem;
			z-index: -1;
			transform: translateZ(-1rem);
		}
	}
	span.enlarge {
		display: block;
		width: 40px;
		height: 40px;
		pointer-events: none;
		background-color: rgba($black, 0.5);
		background-image: url(/wp-content/themes/columbian2/static/img/icons/enlarge.svg);
		position: absolute;
		background-repeat: no-repeat;
		background-size: 50%;
		background-position: center;
		margin-left: 5px;
		margin-top: 5px;
		border-radius: 100%;
		border: 2px solid rgba(255, 255, 255, 0.5);
		z-index: 1;
	}
	img[data-enlarge], img[data-gallery], img[data-video] { // enlarge photo to full screen
		cursor: zoom-in;
		transition: 0.2s ease;

		&:hover {
			filter: brightness(125%);
		}
		& + .instruction {
			position: absolute;
			color: $white;
			padding: 1rem;
			font-family: $font-family-headline;
			font-size: 1.5rem;
			line-height: 1;
			text-align: center;
			background-color: rgba($black, 0.5);
			pointer-events: none;
			// margin-bottom: -1rem;
			// width: calc(100% - 30px);
			width: 100%;
			// right: 15px;
			// left: 15px;
			top: 75%;

			&:before {
				content: '';
				display: inline-block;
				width: 1.5rem;
				height: 1.5rem;
				margin-right: 0.75rem;
				background-image: url(/wp-content/themes/columbian2/static/img/icons/open-white.svg);
				background-repeat: no-repeat;
				background-size: cover;
				vertical-align: -2px;
			}
		}
	}
}



// Columbian Homes advertisement
#col-homes .photo {
	overflow: hidden;
	height: 100%;

	a {
		display: block;
		height: 100%;
		width: 100%;
		min-height: 202px;
		background-size: cover;
		background-position: center;
		transition: transform 0.5s ease;

		&:hover {
			transform: scale(1.2);
		}

		@include media-breakpoint-down(md) {
			background-size: cover;

			&:hover {
				background-size: cover;
			}
		}
		@include media-breakpoint-down(sm) {
			min-height: 125px;
		}
	}
}


.related-stories {
	a {
		color: $black;
	}
	.related-story {
		&:nth-child(odd) {
			.headline {
				@include media-breakpoint-down(sm) {
					background-color: $light;
				}
			}
		}
		.bg-cover {
			@include media-breakpoint-down(sm) {
				min-height: auto;
				height: 100%;
			}
		}
		.headline {
			@include media-breakpoint-down(sm) {
				height: 100%;
			}
			@include media-breakpoint-up(md) {
				background-color: $light;
			}
			.highlighted-headline {
				font-family: $font-family-headline;
				font-size: 1.25rem;
				line-height: 1.4;

				.badge {
					font-size: 0.75rem;
					vertical-align: 3px;
				}
			}
		}
	}
}

iframe.flippxp-frame {
	margin-bottom: map-get( $spacers, 7 ) !important;
}


// comments loader button
/* #dcl_comment_btn:before {
	content: '';
	background-image: url(/wp-content/themes/columbian2/static/img/icons/comments-white.svg);
	margin-right: 0.35rem;
	width: 24px;
	height: 19px;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	vertical-align: -4px;
} */
