#top-bar {
	z-index: 10002;
	background-color: rgba( $white, 0.95 );

	@media print {
		position: static !important;
	}
	#open-menu-btn {
		font-size: 18px;

		/* svg {
			vertical-align: -4px;
			opacity: .67;
		} */
		.menu-icon {
			display: inline-block;
			height: 12px;
			margin-right: 3px;

			@include media-breakpoint-down(xs) {
				margin-right: 0;
			}
			span,
			span:before,
			span:after {
				width: 20px;
				height: 2px;
				background-color: rgba( $black, 0.33 );
				content: '';
				position: relative;
				display: block;
				transition: .15s background-color ease-in-out, .5s margin-top ease,.5s transform ease,.5s -webkit-transform ease;
			}
			span:before {
				top: 5px;
			}
			span:after {
				top: 8px;
			}
			@at-root #top-bar.menu-show #open-menu-btn .menu-icon {
				span {
					margin-top: 5px;
					transform: rotate(-45deg);
				}
				span:after {
					margin-top: -10px;
					transform: rotate(90deg);
				}
				span:before {
					transform: rotate(45deg);
					opacity: 0;
				}
			}
		}
		&:hover .menu-icon span,
		&:hover .menu-icon span:before,
		&:hover .menu-icon span:after,
		.menu-show & .menu-icon span,
		.menu-show & .menu-icon span:before,
		.menu-show & .menu-icon span:after {
			background-color: rgba( $white, 0.5 );
		}
		.menu-btn-label {
			letter-spacing: -0.04rem;
		}
	}
	.btn {
		font-size: 16px;
	}
	#logo {
		@include media-breakpoint-up(lg) {
			max-width: 355px;
			// max-height: 60px;
			padding: 6px 0;
		}
		@include media-breakpoint-down(md) {
			max-width: 230px;
			// max-height: 35px;
			padding: 2px 0;
		}
	}
	#logo-tagline {
		overflow-y: hidden;

		img {
			@include media-breakpoint-up(lg) {
				max-width: 290px;
			}
			@include media-breakpoint-down(md) {
				max-width: 190px;
			}
		}
	}
	&.position-fixed + div {
		margin-top: 67px;
	}
	&.position-sticky, &.position-fixed {
		top: 0;
		transition: 0.2s ease;

		@include media-breakpoint-down(md) {
			#sentinal-top.has-top-ad + & {
				top: -1px; // for some reason in some pixel widths, a pixel gap was displaying above #top-bar when the top ad is on the page (above the #top-bar)
			}
		}

		#logo {
			transition: max-width 0.2s ease;
		}
		#logo-tagline {
			transition: height 0.2s ease;
			height: 19px;
			padding-top: 6px;

			@include media-breakpoint-down(sm) {
				padding-top: 0;
			}
		}
		#misc-btns .subscribe-btn {
			padding-left: $spacer * 0.5;
			padding-right: $spacer * 0.5;

			@include media-breakpoint-down(sm) {
				transition: width 0.2s ease, padding 0.2s ease, border-width 0.2s ease;
				overflow-x: hidden;
				width: 0;
				border-width: 0;
				padding-left: 0;
				padding-right: 0;
			}
		}
		&.stuck {
			box-shadow: 0 5px 10px rgba( $black, 0.08 );

			@include media-breakpoint-up(lg) {
				margin-bottom: 26px; // an average, not pixel-perfect

				#logo {
					max-width: 170px;
					// max-height: 35px;
				}
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 44px;
			}
			#logo-col {
				@media screen and ( max-width: 435px ) {
					padding-left: 0;
					padding-right: 0;
				}
			}
			#logo {
				// fix for IE11 to make logo properly scaled
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					max-height: none;

					@include media-breakpoint-only(xs) { width: 157px; height: 27px; }
					@include media-breakpoint-only(sm) { width: 251px; height: 44px; }
					@include media-breakpoint-only(md) { width: 221px; height: 39px; }
					@include media-breakpoint-only(lg) { width: 296px; height: 52px; }
					@include media-breakpoint-only(xl) { width: 445px; height: 78px; }
				}
			}
			#logo-tagline {
				height: 0;
				padding-top: 0;
			}
			@include media-breakpoint-down(sm) {
				#misc-btns .subscribe-btn {
					width: 100px;
					border-width: $btn-border-width;
					padding-left: $btn-padding-x-sm;
					padding-right: $btn-padding-x-sm;

					@media screen and ( max-width: 435px ) {
						width: auto;
						padding-left: 1.5vw;
						padding-right: 1.5vw;
						font-size: 3vw;
					}
				}
			}
		}

		// fix for wordpress top admin bar
		@media screen and (max-width: 782.98px) {
			body.admin-bar & {
				top: 46px;
			}
		}
		@media screen and (min-width: 783px) {
			body.admin-bar & {
				top: 32px;
			}
		}
	}
	#top-nav-my-account-btn {
		svg {
			vertical-align: 0;
			margin-right: 3px;
			opacity: .67;

			@include media-breakpoint-down(xs) {
				margin-right: 0;
				vertical-align: sub;
				width: 16px;
				height: auto;
			}
		}
		&.shares-col-with-subscribe-btn svg {
			@include media-breakpoint-down(sm) {
				margin-right: 0;
				vertical-align: sub;
				width: 16px;
				height: auto;
			}
		}
		& + #account-menu {
			overflow: hidden;
			position: absolute;
			top: 33px;
			right: 0;
			opacity: 0;
			visibility: hidden;
			// transform: translateX(50%);
			transform: scale(0.9);
			transition: 0.5s ease;
			background-color: $white;
			box-shadow: 0 0 0.75rem rgba($black, 0.25);
			z-index: 10003;

			.inside {
				padding: 0.75rem;
				min-width: 15rem;
				max-width: 90vw;

				&.logged-in .username {
					text-overflow: ellipsis;
					overflow: hidden;

					&:empty {
						display: none;
					}
				}
				&:active {
					visibility: visible;
					opacity: 1;
					// transform: translateX(0);
					transform: scale(1);
				}
			}
		}
		&:focus + #account-menu {
			visibility: visible;
			opacity: 1;
			// transform: translateX(0);
			transform: scale(1);
		}
	}
	#top-nav-search-btn svg {
		vertical-align: -5px;
	}
}

#supplement-bar-mobile {
	font-size: 85%;

	section {
		.item {
			color: $secondary;
		}
		&:first-child {
			.item:not(:last-child) {
				margin-right: 0.75rem;
				padding-right: 0.75rem;
				border-right: 1px solid $light-bootstrap-default;
			}
		}
		&:last-child {
			.item:not(:first-child) {
				margin-left: 0.75rem;
				padding-left: 0.75rem;
				border-left: 1px solid $light-bootstrap-default;
			}
		}
	}
}
#supplement-bar {
	font-size: 14px;
	color: $gray-400;

	#supplement-bar-date {
		padding-top: 0.67rem;
		display: flex;
		flex-flow: row wrap;
		overflow: hidden;
		height: 2rem;
	}
	nav {
		ul {
			margin-bottom: 0.5rem;

			li.menu-item {
				margin-right: 0;
				display: inline-block;

				a.nav-link {
					display: block;
					font-size: 16px;
					color: $gray-400;
					// padding-bottom: .25rem;
					transition: border-bottom 0.2s ease;
					// border-bottom: 10px double transparent;

					&.current {
						font-weight: bold;
						// border-bottom: 1px solid rgba($gray-400, 0.5);

						&:after {
							background-color: rgba($gray-400, 0.4);
						}
					}
					&:hover {
						text-decoration: none;
						// border-bottom: 3px solid rgba($columbian-faded, 0.4);
					}
					&:after {
						content: '';
						width: 0;
						height: 3px;
						margin-top: 4px;
						background-color: rgba($columbian-faded, 0.4);
						display: block;
						transition: 0.2s ease;
					}
					&.text-success:after {
						background-color: rgba($success, 0.4);
					}
					&:hover:after {
						width: 100%;
					}
				}
				&.menu-item-has-children {
					cursor: pointer;

					&:hover a.nav-link {
						// border-bottom: 10px double transparent;
						color: $columbian-faded !important;

						&:after {
							border-color: rgba( $columbian-faded, 0.5 );
							border-width: 0 3px 3px 0;
							margin-right: 0;
						}
						& + .dropdown-menu, & + .dropdown-menu:hover {
							visibility: visible;
							opacity: 1;

							.dropdown-menu-inside {
								top: 0.5rem;
							}
						}
					}
					a.nav-link {
						display: inline-block;
						transition: color 0.15s ease-in-out;
						pointer-events: none;
						// cursor: pointer;

						&:after { // right arrow icon
							content: '';
							display: inline-block;
							width: auto;
							float: right;
							background-color: transparent;
							border: solid rgba( $black, 0.2 );
							border-width: 0 2px 2px 0;
							transform: rotate(45deg);
							padding: 2px;
							margin-top: 0.5rem;
							margin-left: 0.4rem;
							transition: border 0.15s ease-in-out, margin-right 0.15s ease-in-out;
							margin-right: 1px;
						}
					}
					.dropdown-menu {
						visibility: hidden;
						opacity: 0;
						position: absolute;
						z-index: 3;
						transition: opacity 0.15s ease-in-out;
						// left: 0;
						right: 0;
						cursor: auto;
						width: 60%;

						@include media-breakpoint-down(lg) {
							width: 75%;
						}
						.dropdown-menu-inside {
							position: relative;
							display: flex;
							flex-wrap: wrap;
							// justify-content: center;
							margin: 0 auto;
							padding: 0.75rem 0;
							// top: -0.75rem;
							background-color: rgba( $white, 0.98 );
							box-shadow: 0rem 0.5rem 1.5rem rgba( $black, 0.125 );
							border-top: 1px solid $gray-100-bootstrap-default;
							transition: top 0.15s ease-in-out;
							// width: fit-content;
							align-items: center;

							a.dropdown-item {
								display: inline-block;
								// padding: 1rem 2rem;
								padding: .75rem 2rem;
								color: $gray-800;
								// font-family: $font-family-subhead;
								// font-size: 1rem;
								// width: 30rem;
								transition: background-color 0.2s ease;
								flex: 0 0 33.33333%;
								max-width: 33.33333%;
							    text-align: left;
								font-size: 100%;

								&.current {
									color: $black;
									background-color: rgba( $gray-200, 0.25 );
									font-weight: bold;
								}
								&:hover {
									color: $black;
									text-decoration: none;
									background-color: rgba( $gray-300, 0.5 );
								}
							}
						}
					}
				}
			}
		}
	}
	#weather-widget {
		padding-top: 0.3rem;

		.btn {
			line-height: 1;
		}
	}
}


#content-overlay, #media-viewer {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	z-index: 9999;
	background-color: rgba( $black, 0.9 );
	visibility: hidden;
	opacity: 0;
	transition: 0.5s ease;

	button.close {
		position: absolute;
		top: 8rem;
		right: 1.5rem;
		font-size: 2rem;
		color: $white;
		line-height: 0.5;
		opacity: inherit;
		outline: 0;
		z-index: 1;
		text-shadow: none;
		font-weight: 400;

		span {
			display: inline-block;
			padding: .7rem;
			// background-color: darken( $white, 10% );
			// box-shadow: -4px 0px 0 $columbian-faded, -5px 4px 0px 0px rgba($columbian-light, 0.4);
			border: 2px solid rgba($white, 0.5);
			// border-top-width: 1px;
			// border-left-width: 2px;
			// border-bottom-width: 3px;
			border-radius: 100%;
			transition: background-color .15s ease-in-out, color .15s ease-in-out, box-shadow .3s ease;

			&:hover {
				background-color: $white;
				box-shadow: 0px 0px 0 5px rgba($columbian-faded, 0.6), 0px 0px 0 10px rgba($columbian-light, 0.3);
				color: $columbian-faded;
			}
		}
		/* @include media-breakpoint-down(sm) {
			& {
				display: none;
			}
		} */
	}
}
body {
	&.overlay-show #content-overlay, &.media-viewer-open #media-viewer {
		visibility: visible;
		opacity: 1;
	}
	&.overlay-show, &.media-viewer-open {
		overflow: hidden; // disables scrollbar when overlay is open
	}
}

#media-viewer {
	z-index: 9998;
	color: $white;

	.inside-content {
		position: absolute;
		top: calc(68px + 1rem);
		bottom: 1rem;
		width: 100%;
		@include media-breakpoint-down(sm) {
			overflow-y: auto;
		}

		.media {
			@include media-breakpoint-up(md) {
				height: 100%;
			}
			span {
				position: relative;
				margin: 0 auto;
				height: 100%;
				width: 100%;
				display: flex;

				/* &:hover .external-actions {
					opacity: 1;
					background-color: rgba($black, 0.1);
				}
				.external-actions {
					position: absolute;
					top: 0;
					right: 0;
					padding: 0.5rem;
					transition: 0.2s ease;
					opacity: 0.5;

					a {
						display: inline-block;
						line-height: 1;

						img {
							width: 23px;
							height: 23px;
							padding: 2px;
						}
					}
				} */
				img#photo {
					margin: auto;
					max-height: 100%;
					cursor: pointer;
					align-self: center;
					cursor: zoom-out;

					@include media-breakpoint-down(sm) {
						margin-bottom: 1rem;
					}
				}
			}
		}
		.details {
			// margin-top: 9rem;
			@include media-breakpoint-up(md) {
				border-left: 1px solid $dark;
				overflow-y: scroll;
				position: absolute;
				right: 0;
				bottom: 0;
				top: 0;
			}
			@include media-breakpoint-down(sm) {
				margin-top: auto;
			}
			button.close {
				position: static;
				// text-align: right;
				// margin-right: 1.5rem;
				margin-bottom: 1rem;
				cursor: zoom-out;
				width: 43px;
				align-self: flex-end;
				top: 6rem;
			}
			.title:empty, .pagination:empty {
				display: none;
			}
			.caption {
				// overflow-y: auto;
				font-size: .9rem;
			}
			nav {
				text-align: center;

				button {
					padding: 0.25rem;
					line-height: 1;

					img {
						width: 14px;
						height: 20px;
					}
					&[data-direction="prev"] img {
						transform: rotate(180deg);
					}
				}
			}
			.external-actions {
				line-height: 3rem;

				.btn:hover {
					background-color: $columbian;
				}
			}
		}
		// fix for wordpress top admin bar
		@at-root body.admin-bar #media-viewer .inside-content {
			top: calc(68px + 1rem + 32px);
		}
		@media screen and (min-width: 601px) and (max-width: 782.98px) {
			@at-root body.admin-bar #media-viewer .inside-content {
				top: calc(68px + 1rem + 46px);
			}
		}
	}
}


#sentinal-top {
	/* visibility: visible;
	opacity: 1;
	max-height: 400px;
	overflow: hidden;
	transition: 0.5s ease; */

	body.overlay-show &, body.media-viewer-open & {
		/* visibility: hidden;
		opacity: 0;
		max-height: 0; */
		display: none;
	}
}
/* #top-bar.menu-show #open-menu-btn svg {
	line {
		transition: 0.5s ease;
	}
	line:nth-child(1) {
		transform: rotate(45deg);
	}
	line:nth-child(2) {
		transform: rotate(-45deg);
	}
	line:nth-child(3) {
		// transform: rotate(45deg);
		opacity:0;
	}
} */

nav#nav-main {
	// display: none;
	position: absolute;
	top: 3rem;
	z-index: 10001;
	background-color: rgba( $white, 0.96 );
	box-shadow: 0 3px 10px rgba( $black, 0.25 );

	#top-bar & {
		transition: 0.5s ease;
	}
	#top-bar.menu-show & {
		visibility: visible;
		opacity: 1;
		transform: translateX(0);
	}
	#top-bar.menu-hide & {
		visibility: hidden;
		opacity: 0;
		transform: translateX(-50%);
	}
	.display-username {
		.username {
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			color: darken( theme-color-level(columbian-faded, $alert-color-level), 10% );
			font-weight: $font-weight-bold;

			&:empty {
				display: none !important;
			}
			&:not(:empty):before {
				content: 'Logged in as ';
				font-weight: $font-weight-base;
				color: theme-color-level(columbian-faded, $alert-color-level);
			}
		}
	}
	#main-menu-top-items {
		.icon {
			margin-right: 0.35rem;
			opacity: 0.67;
		}
	}

	ul {
		min-width: 30%;
		margin-bottom: 0;

		li.menu-item {
			&.separate {
				margin-top: 0.75rem;
				padding-top: 0.75rem;
				border-top: 1px solid $light-bootstrap-default;
			}
			.menu-item-inside {
				a.nav-link {
					display: block;
					padding: 1rem 2rem 1rem 3rem;
					color: $gray-800;
					font-family: $font-family-headline;
					font-size: 1.1rem;
					transition: background-color 0.2s ease, box-shadow 0.2s ease;
					width: 25rem;

					&:not([href]), &[href='javascript:;'] {
						cursor: default;
						outline: 0;
					}
					&.current {
						color: $black;
						background-color: rgba( $gray-200, 0.25 );
						font-weight: bold;
					}

					&:hover {
						color: $black;
						text-decoration: none;
						background-color: rgba( $gray-300, 0.5 ) !important;

						@include media-breakpoint-up(md) {
							& + .dropdown-menu {
								display: block;
							}
						}
					}
					@include media-breakpoint-down(sm) {
						&:focus + .dropdown-menu {
							display: block;
						}
					}
					&:focus {
						outline: 0;
						box-shadow: $btn-focus-box-shadow;
					}
				}
				&:hover a.nav-link { // what color the top-level links are when your mouse is on the submenu dropdowns
					background-color: rgba( $gray-300, 0.25 );
				}
			}
			&.menu-item-has-children a.nav-link:after { // right arrow icon
				content: '';
				display: inline-block;
				float: right;
				border: solid rgba( $black, 0.2 );
				border-width: 0 3px 3px 0;
				transform: rotate(-45deg);
				padding: 3px;
				margin-top: 0.45rem;
			}
			.dropdown-menu {
				display: none;
				position: absolute;
				left: 24rem;

				&:hover {
					display: block;
				}
				.dropdown-menu-inside {
					position: relative;
					top: -3rem;
					background-color: rgba( $white, 0.96 );
					box-shadow: 0 3px 10px rgba( $black, 0.25 );

					a.dropdown-item {
						display: block;
						padding: 0.75rem 2rem 0.75rem 3rem;
						color: $gray-800;
						font-family: $font-family-subhead;
						font-size: 1rem;
						width: 22rem;
						transition: background-color 0.2s ease, box-shadow 0.2s ease;

						&.current {
							color: $black;
							background-color: rgba( $gray-200, 0.25 );
							font-weight: bold;
						}
						&:hover {
							color: $black;
							text-decoration: none;
							background-color: rgba( $gray-300, 0.5 );
						}
						&:focus {
							outline: 0;
							box-shadow: $btn-focus-box-shadow;
						}
						@include media-breakpoint-up(md) {
							&.dropdown-lead {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	&.menu-condense ul li.menu-item .menu-item-inside a.nav-link {
		&:not([href]), &[href='javascript:;'] {
			cursor: pointer;
		}
	}
	form.form-row {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

@include media-breakpoint-down(sm) {
	#top-bar {
		&.menu-show {
			& + div + div + div + #content-overlay button.close {
				display: none;
			}
			& #misc-btns .subscribe-btn {
				width: 0 !important;
				border-width: 0 !important;
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
		.menu {
			position: static;
		}
	}
	nav#nav-main {
		left: 0;
		right: 0;
		top: 67px;
		overflow-y: auto;
		position: fixed;
		bottom: 0;

		body.admin-bar & {
			top: 113px;
		}

		ul {
			li.menu-item {
				&.menu-item-has-children a.nav-link {
					// right arrow icon
					&:after {
						transform: rotate(45deg);
						transition: transform 0.2s ease;
					}
					&:focus:after {
						transform: rotate(225deg);
					}

				}
				.menu-item-inside {
					a.nav-link {
						width: auto;
						padding: 1rem 2rem;
					}
					.dropdown-menu {
						position: static;

						.dropdown-menu-inside {
							position: static;
							box-shadow: none;
							padding-bottom: 1rem;

							a.dropdown-item {
								width: auto;
							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up(md) {
	nav#nav-main.menu-condense {
		left: 0;
		right: 0;
		top: 67px;
		overflow-y: scroll;
		position: fixed;
		bottom: 0;
		max-width: 400px;

		body.admin-bar & {
			top: 96px;
		}
		ul {
			li.menu-item {
				&.menu-item-has-children a.nav-link {
					// right arrow icon
					&:after {
						transform: rotate(45deg);
						transition: transform 0.2s ease;
					}
					&:focus:after {
						transform: rotate(225deg);
					}

				}
				.menu-item-inside {
					a.nav-link {
						width: auto;
						padding: 1rem 2rem;

						&:focus {
							color: $black;
							text-decoration: none;
							background-color: rgba( $gray-300, 0.5 ) !important;

							& + .dropdown-menu {
								display: block;
							}
						}
						&:hover:not(:focus) + .dropdown-menu {
							display: none;
						}
					}
					.dropdown-menu {
						position: static;

						.dropdown-menu-inside {
							position: static;
							box-shadow: none;
							padding-bottom: 1rem;

							a.dropdown-item {
								width: auto;

								&.dropdown-lead {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}

#search-action-form {
	z-index: 10000;
	visibility: hidden;
	opacity: 0;
	transition: 0.5s ease;
	position: fixed;
	left: 0;
	right: 0;
	// height: 100%;
    top: 18rem;
	// pointer-events: none;

	@include media-breakpoint-down(md) {
		top: 12rem;
	}
	&.search-show {
		visibility: visible;
		opacity: 1;
	}
	form {
		// pointer-events: all;
	}
	input[type="text"], button {
		height: 100%;
	}
	input[type="text"] {
		padding: 2.5rem 1.5rem;
		// line-height: 12rem;
		font-family: $font-family-headline;
		font-size: 2rem;
		transition: background-color 0.2s ease;

		&:invalid {
			background-color: darken( $white, 10% );
			// background-color: $gray-100-bootstrap-default;
		}

		&:hover, &:focus {
			background-color: $white;
		}
	}
	button img {
		width: 100%;
		height: auto;
		// width: auto;
		// height: 80%;
	}
}

// fix top nav bar on mobile when WP admin is logged in
@media screen and (max-width: 600px) {
	body.admin-bar {
		#wpadminbar {
			position: fixed;
		}
		nav#nav-main {
			top: 113px;
		}
		#top-bar.menu-show {
			top: 46px;
		}
	}
}
@media (min-width: 768px) and (max-width: 782px) {
	body.admin-bar nav#nav-main.menu-condense {
		top: 112px;
	}
}
