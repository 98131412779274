.font-headline {
	font-family: $font-family-headline !important;
}
.font-sans {
	font-family: $font-family-sans-serif !important;
}
.font-serif {
	font-family: $font-family-subhead !important;
}

::selection {
	background-color: $columbian-faded;
	color: $white;
}

.post-type-page h1 {
	margin-bottom: 2rem;
}

.breadcrumbs {
	padding-left: 0;
	list-style: none;
	display: inline-block;
	margin-bottom: 3rem;
	padding: 0.5rem 1rem;
	background-color: $gray-100;

	li {
		display: inline-block;
		margin-bottom: 0 !important;

		&:not(:last-child) {
			margin-right: 0.5rem;

			&:after {
				content: '';
				display: inline-block;
				background-image: url('/wp-content/themes/columbian2/static/img/icons/chevron-right.svg');
				background-repeat: no-repeat;
				background-size: contain;
				width: 7px;
				height: 10px;
				opacity: 0.25;
				margin-left: 0.5rem;
			}
		}
	}
}

.excerpt {
	font-family: $font-family-subhead;
}
a:hover .excerpt {
	text-decoration: none;
}

.story {
	.story-attributes {
		.badge {
			font-size: 85%;
			vertical-align: 1px;
			padding: 4px;
			line-height: 0.7;
		}
	}
}

.opacity-25 {
	opacity: 0.25;
}
.opacity-50 {
	opacity: 0.5;
}
.opacity-75 {
	opacity: 0.75;
}
.flip {
	transform: rotate(180deg);
}

time {
	font-family: $font-family-sans-serif;
	font-weight: bold;
	color: $gray-400;
	font-size: 11px;

	&.recent {
		color: $red;
	}
}
section.related-stories .details {
	line-height: 1;

	.category {
		font-family: $font-family-sans-serif;
		font-weight: bold;
		color: $gray-600;
		font-size: 11px;
	}
}

.module-heading {
	font-family: $font-family-headline;
	font-size: 22px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 6px;
	margin-bottom: 1.5rem;

	&.module-heading-lg {
		font-size: 30px;
		letter-spacing: 6px;
	}
	a {
		color: $black;
	}
}

.bg-dark-transparent {
	background-color: rgba($black, 0.33);
	text-shadow: 2px 3px 10px rgba($black, 0.5);
}
.bg-columbian a,
.bg-dark a {
	color: $white;
}
.bg-insignia {
	background-image: url(/wp-content/themes/columbian2/static/img/columbian-insignia-opaque-new.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 100% 35px;
}
.bg-cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@include media-breakpoint-down(md) {
		min-height: 150px;
	}
	@include media-breakpoint-up(lg) {
		&.thumbnail {
			min-height: 224px;
		}
	}
}


.flex-columns {
	flex-direction: column;
}

.card {
	p {
		color: $gray-600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.03rem;
	}
	label {
		display: inline-block;
		color: $gray-800;
		font-size: 14px;
		line-height: 20px;
		font-weight: bold;
	}
}

.title-lined {
	text-align: center;
	font-family: $font-family-headline;
	font-size: 22px;
	text-transform: uppercase;
	letter-spacing: 6px;
	margin: auto auto 3rem;

	&.small {
		font-size: 18px;
		letter-spacing: 13px;
		margin-bottom: 3rem;
		color: $gray-700;

		span, a {
			padding-left: calc(1.5rem + 13px); // compensation for right offset that letter-spacing creates after last letter
			padding-right: 1.5rem;
		}
	}
	a.bg-light {
		&:hover, &:focus {
			background-color: $light !important;
		}
	}
	@include media-breakpoint-up(md) {
		margin: 1rem auto 3.5rem;
		line-height: 0;
		border-bottom: 1px solid $gray-300;

		&.small {
			border-color: $gray-200;
		}
		span, a {
			background-color: $white;
			padding-left: calc(2rem + 6px); // compensation for right offset that letter-spacing creates after last letter
			padding-right: 2rem;
		}
	}
	@include media-breakpoint-up(lg) {
		width: 75%;
	}
}
.letter-spacing {
	letter-spacing: 6px;
}

input[type=text].input-dark {
	background-color: $gray-300;
	border: none;
	color: $white;
	font-family: $font-family-sans-serif;
	font-size: 13px;
	line-height: 17px;
	font-weight: 400;
	transition: 0.15s ease-in-out;

	&::placeholder {
		color: $gray-100;
	}
	&:hover, &:focus {
		background-color: $gray-400;
	}
}

// hide recaptcha badge from displaying in lower right corner of pages
.grecaptcha-badge {
	display: none !important;
}

// author archive pages
body.author {
	.contact-details {
		font-size: 14px;

		a {
			padding: 0.15rem;
		}
	}
}

.accordion {
	.card-header {
		.btn-link {
			white-space: normal;
			padding-right: 2rem;
			padding-left: 0;

			&:hover {
				color: inherit;
			}
			// plus/minus sign
			&:before, &:after {
				position: absolute;
				content: '';
				display: block;
				background-color: $gray-600;
				pointer-events: none;
			}
			&:before {
				width: 20px;
				height: 4px;
				top: 33px;
				right: 20px;
			}
			&:after {
				transition: .3s ease-in-out;
				width: 4px;
				right: 28px;
				height: 4px;
				top: 33px;
			}
			&.collapsed:after {
				height: 20px;
				top: 25px;
			}
		}
	}
	.card.alert-warning {
		.card-header {
			.btn-link {
				&:before, &:after {
					background-color: #7f781c;
				}
			}
		}
	}
}


.pdf-link {
	position: relative;
	overflow: hidden;
	transition: filter 0.15s ease;
	display: inline-block;

	&:hover {
		filter: saturate(0);
	}
	&:before {
		content: 'View PDF';
		position: absolute;
		font-family: $font-family-headline;
		background-color: rgba($black, 0.75);
		color: $white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1.5);
		transition: 0.15s ease;
		z-index: 1;
		height: 9rem;
		width: 9rem;
		line-height: 11rem;
		text-align: center;
		border-radius: 100%;
		background-image: url(/wp-content/themes/columbian2/static/img/icons/pdf-white.svg);
		background-repeat: no-repeat;
		background-position: 50% 30%;
		background-size: 28px;
		border: 4px solid rgba(0, 0, 0, 0.5);
		opacity: 0;
		box-shadow: 0 0 20px #fff, 0 0 20px #fff;
	}
	&:hover:before {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
}



// for tighter columns when ad is in right-most column
.row.row-ad {
	flex-wrap: nowrap;

	.col-ad {
		// flex: 1 1 auto;
		// padding: 0 15px;
		width: auto;

		&:last-child {
			flex: 0 0 auto;
		}
	}
}


body.media-viewer-open .tooltip {
	z-index: 10000; // make tooltips visible when media viewer is open

	// revert tooltip colors in media viewer to display easier on black background
	.tooltip-inner {
		background-color: #fff;
		color: #000;
	}
	.arrow {
		&::before {
			border-top-color: #fff;
		}
	}
}

.wp-polls {
	transition: opacity 0.2s ease;

	&[style*=opacity]:not([style*="opacity: 1"]) {
		opacity: 0.5 !important;
	}
	.progress {
		animation: progressBar 1s ease-in-out;

		.progress-bar {
			font-size: 100%;
			padding: 0 5px;
		}
	}
}
@keyframes progressBar {
	0%   { width: 0; }
	100% { width: 100%; }
}
.wp-polls-loading {
	height: auto;
	line-height: 1;
	background-color: $columbian;
	padding: 0.75rem;
	color: $white;
	margin-top: 1rem;
	font-weight: bold;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 20%;
	left: 50%;
	margin-left: -4.5rem /* width divided by 2 */;
	width: 9rem;

	&:before {
		content: '';
		display: inline-block;
		width: 1.25rem;
		height: 1.25rem;
		vertical-align: text-bottom;
		border: .25em solid currentColor;
		border-right-color: transparent;
		border-radius: 50%;
		animation: spinner-border .75s linear infinite;
		font-size: 70%;
		margin-right: 0.4rem;
	}
	img.wp-polls-image {
		display: none;
		/*filter: saturate(12) hue-rotate(160deg);
		margin-right: 0.1rem;
		height: 11px;
		width: 11px;
		margin-top: -3px;*/
	}
}

.subcategory-story-list {
	h3 {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		margin-bottom: 1rem;
		font-size: 90%;
	}
	.badge {
		font-size: 0.75rem;
		font-family: $font-family-sans-serif;
		vertical-align: 3px;
	}
}


.alert-breaking {
	background-color: #de0000;
	font-size: 104%;

	&, a {
		color: $white;
	}
	.close {
		text-shadow: none;
		color: $white;
		outline-color: lighten( #de0000, 35% );
		transition: opacity 0.15s ease-in-out;

		&:not(:disabled):not(.disabled):focus,
		&:not(:disabled):not(.disabled):hover {
			color: $white;
			opacity: 1;
		}
	}
	&.alert-traffic {
		background-color: $dark;

		&, a { color: $white; }
		.close { outline-color: #999; }
	}
	&.alert-school {
		background-color: $warning;

		&, a { color: $black; }
		.close {
			color: $black;
			outline-color: darken( $warning, 25% );

			&:not(:disabled):not(.disabled):focus,
			&:not(:disabled):not(.disabled):hover {
				color: $black;
			}
		}
		.border-white { border-color: $black !important; }
	}
	&.alert-cta {
		background-color: theme-color-level( columbian, $alert-bg-level );
		border: none;
		color: theme-color-level( columbian, $alert-color-level );

		.alert-link {
			color: theme-color-level( columbian, $alert-color-level );
		}
		.close {
			color: theme-color-level( columbian, $alert-color-level );
			outline-color: $columbian;

			&:not(:disabled):not(.disabled):focus,
			&:not(:disabled):not(.disabled):hover {
				color: theme-color-level( columbian, $alert-color-level );
			}
		}
	}
}

#scroll-to-top-btn-container {
	position: absolute;
	top: 0;
	right: 0;
	height: 100vh;
	width: 1px; // MS Edge requires a nominal dimension

	#scroll-to-top-btn {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		opacity: 0.75;
		visibility: visible;
		transform: rotate(270deg);
		width: 2.8rem;
		height: 2.8rem;
		background-image: url(/wp-content/themes/columbian2/static/img/icons/arrow-right-white.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 65%;
		transition: 0.2s ease;
		z-index: 9996;

		&:hover {
			opacity: 1;
		}
	}
	&.hide #scroll-to-top-btn {
		opacity: 0;
		visibility: hidden;
	}
}

.ep-highlight {
	font-style: normal !important;
	font-weight: bold;
	padding: 0.1em 0.2em;
	color: $black;
	background-color: rgba( $columbian-faded, 0.1 ) !important;
}

.premium-content-label {
	display: none;

	&.unlocked {
		display: inline-block;
	}
	&:after {
		content: url('/wp-content/themes/columbian2/static/img/icons/locked-white.svg');
		width: 9px;
		height: 9px;
		display: inline-block;
		margin-left: 5px;
		vertical-align: -1px;
	}
	&.unlocked:after {
		content: url('/wp-content/themes/columbian2/static/img/icons/unlocked-white.svg');
		width: 11px;
		height: 12px;
		vertical-align: 0px;
	}
}


.featured-media figure.figure {
	max-width: 446px;
}


// ad containers

#ad-homepage-jumbo-billboard, #ad-default-jumbo-billboard {
	display: none;
}
[data-aa-adunit] {
    text-align: center;
}
[data-aa-adunit="/2444258/v3_default_masthead_billboard"],
[data-aa-adunit="/2444258/v3_default_full_width_above_footer"],
[data-aa-adunit="/2444258/v3_instory_full_width_above_footer"],
[data-aa-adunit="/2444258/v3_homepage_full_width_d"] {
	&:not(:empty) {
		padding: 1.5rem 0;
		background-color: $light;
	}
}


// subscribe button style variants for A/B testing
/*.subscribe-btn {
	&.variant-1 {
		// brand blues gradient
		background: linear-gradient( 195deg, $columbian, $columbian-light );
		background-size: 100%;
		transition: background-size 0.2s ease;
		border: none;
		// padding: 0.6rem 0.9rem;

		&:hover {
			background-size: 500%;
		}
	}
	&.variant-2 {
		// vivid red
		$vivid-red: #cf2e2e;
		background-color: $vivid-red;
		border-color: $vivid-red;

		&:hover {
			background-color: darken($vivid-red, 7.5%);
			border-color: darken($vivid-red, 10%);
		}
	}
	&.variant-3 {
		// purple
		$custom-purple: #6610f2;
		background-color: $custom-purple;
		border-color: $custom-purple;

		&:hover {
			background-color: darken($custom-purple, 7.5%);
			border-color: darken($custom-purple, 10%);
		}
	}
	&.variant-4 {
		background: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
		background-size: 100%;
		transition: background-size 0.2s ease;
		border: none;

		&:hover {
			background-size: 500%;
		}
	}
}*/
