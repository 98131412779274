#breaking-news-takeover {
	background-color: black;

	.label {
		line-height: 0;
	}
	@include media-breakpoint-only(md) {
		.container {
			max-width: 100%;
		}
	}
	.related-md-only {
		margin-left: -15px;
		margin-right: -15px;
	}
}

.top-stories {
	.large-feature {
		.story-title {
			color: #fff;
			font-family: $font-family-headline;
			font-size: 2.5rem;
			line-height: 1.3;
		}
		.excerpt {
			color: #fff;
			font-size: 22px;
			line-height: 28px;

			img { // (right arrow)
				height: 17px;
				width: 17px;
				vertical-align: -1px;
			}
		}
		.col-xl-6 { // fix for IE11
			flex-basis: auto;
		}
	}
	.secondary-features {
		.story-title {
			font-family: $font-family-headline;
			font-size: 20px;
			line-height: 27px;

			@include media-breakpoint-up(md) {
				font-weight: bold;
			}
			a {
				color: $dark;
			}
		}
		.excerpt {
			font-size: 17px;
			line-height: 22px;
			color: $gray-600;
			display: inline;
		}
		dl.details {
			float: right;
			margin-bottom: 0;

			dd {
				font-family: $font-family-sans-serif;
				margin-bottom: 0;
				float: left;
				color: $gray-300;
				font-weight: bold;
				font-size: 11px;
				line-height: 15px;
				margin-top: 7px;

				@include media-breakpoint-down(sm) {
					line-height: 12px;
				}
				&.recent {
					color: $red;
				}
				&:not(:last-child) {
					padding-right: 0.25rem;
					margin-right: 0.25rem;
					border-right: $border-width solid $border-color;
				}
				&.premium-content-label:after {
					content: url('/wp-content/themes/columbian2/static/img/icons/locked.svg');
					vertical-align: 0;
					opacity: 0.35;
					margin-left: 4px;
				}
				&.premium-content-label.unlocked:after {
					content: url('/wp-content/themes/columbian2/static/img/icons/unlocked.svg');
				}
			}
		}
		.sponsored {
			background-color: rgba($columbian-faded, .15);

			.story-title {
				a {
					// color: scale-color($columbian, $lightness: -15%, $saturation: 15%);
					color: darken($columbian, 5%);
				}
			}
		}
	}
}

.highlighted-stories { // "Latest Deadlines" & "Trending Now" modules
	.module-heading {
		margin-bottom: 2.25rem;
	}
	.story-title {
		font-family: $font-family-subhead;
		font-size: 19px;
		line-height: 24px;
		color: $gray-600;
		margin-bottom: 1.5rem;

		a {
			color: $gray-600;

			.badge {
				font-family: $font-family-sans-serif;
				font-size: 12px;
				vertical-align: 3px;
			}
		}
		time {
			font-family: $font-family-sans-serif;
			display: inline-block;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.center-highlight {
		.story-title {
			font-family: $font-family-headline;
			font-size: 32px;
			line-height: 43px;
			color: $black;
			margin-bottom: 1rem;

			img.has-video-icon {
				vertical-align: 5px !important;
				margin-left: 0.75rem !important;
			}
			.badge {
				font-size: 0.75rem;
				font-family: $font-family-sans-serif;
				vertical-align: 8px;
			}
		}
		.excerpt {
			font-size: 21px;
			line-height: 30px;
			color: $gray-600;
		}
	}
}

.story-group {
	.story-title {
		font-family: $font-family-headline;
		font-size: 18px;
		line-height: 25px;
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}
		&.lead {
			font-weight: bold;
			margin-bottom: 9px;
		}
		.badge {
			font-size: 0.75rem;
			font-family: $font-family-sans-serif;
			vertical-align: 3px;
		}
	}
	a {
		color: $black;
	}
	.excerpt {
		font-size: 16px;
		line-height: 22px;
	}
	.section-more-link {
		display: block;
		font-size: 80%;
		font-family: $font-family-sans-serif;
		text-align: right;
		text-decoration: none;
		padding: 0.2rem 0.5rem;
		margin-top: auto;
		transition: background-color 0.2s ease;

		img {
			width: 6px;
			height: 8px;
		}
	}
}

#weather-widget-full {
	background-color: rgba( $columbian, 0.06 );
	font-family: $font-family-sans-serif;
	text-decoration: none;
	color: $black;
	transition: background-color 0.2s ease;

	&:hover {
		background-color: rgba( $columbian, 0.12 );
	}
	.current {
		font-size: 15px;
		line-height: 27px;

		.temp {
			font-family: $font-family-headline;
			font-size: 36px;
			line-height: 48px;

			sup {
				top: -1.8em;
				left: .15em;
				font-size: 12px;
				color: $columbian;
				font-weight: bold;
				font-family: $font-family-sans-serif;
			}
		}
		.status {
			font-family: $font-family-headline;
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 0.75rem;
		}
		ul li img {
			height: 23px;
		}
	}
	.next-two-days {
		font-size: 14px;
		line-height: 18px;

		.day {
			font-family: $font-family-headline;
			font-size: 24px;
			line-height: 1;
			margin-bottom: 8px;
		}
	}
	.value {
		color: $columbian;
		font-weight: bold;
	}
}

.sponsored-content {
	.story-title {
		font-family: $font-family-headline;
		font-size: 18px;
		line-height: 25px;
		margin-bottom: 18px;
	}
}

#newsletters-signup-cta {
	h4 {
		font-size: 1.4rem;
	}
	.description {
		font-size: 14px;
		line-height: 20px;
	}
	input[type=email] {
		min-width: 0;
		transition: $btn-transition;
	}
}

#events-widget {
	font-family: $font-family-sans-serif;

	.row {
		margin-bottom: 1.25rem;

		&:last-child {
			margin-bottom: 0;
		}
		&:nth-child(4) {
			@include media-breakpoint-only(lg) {
				display: none;
			}
		}
	}
	.month {
		text-transform: uppercase;
		text-align: center;
		font-weight: bold;
		color: $gray-600;
	}
	.day {
		text-align: center;
		font-weight: bold;
		font-size: 27px;
		color: $columbian;
		line-height: 20px;
	}
	.event-title {
		/* font-size: 18px;
		line-height: 25px; */
	}
	time {
		font-size: 15px;
	}
}

.traffic {
	#travel-time {
		transition: background 0.2s ease;
		background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right-white.svg);
		background-repeat: no-repeat;
		background-position: 110% 50%;
		background-size: 1rem;
		background-blend-mode: soft-light;

		&:hover {
			background-position-x: calc(100% - 1rem);
		}
	}
	.btn {
		font-family: $font-family-sans-serif;
		font-size: 15px;
		// font-weight: bold;

		/* &.btn-dark {
			background-color: rgba( $gray-800, 0.5 );
		} */
		img.btn-icon {
			height: 14px;
			// vertical-align: -1px;
			margin-right: 8px;
		}
	}
	.traffic-btns {
		line-height: 2.5;
	}
	.traffic-map {
		a {
			overflow: hidden;
			display: block;

			img {
				max-width: 630px;
				margin-left: 50%;
				transform: translateX(-50%);
				height: 250px;
			}
		}
	}
	.live {
		.label {
			position: absolute;
			font-weight: bold;
			text-transform: uppercase;
			background-color: $black;
			color: $white;
			border-bottom-right-radius: .5rem;
			letter-spacing: 1px;
			padding: .5rem 1.75rem .5rem 1.5rem;
			pointer-events: none;

			&:before {
				content: ' ';
				background-color: #ff1700;
				display: inline-block;
				width: 11px;
				height: 11px;
				border-radius: 100%;
				margin-right: 7px;
			}
		}
		.snapshot {
			display: block;
			background-repeat: no-repeat;
			// background-position: center top;
			background-position: center;
			// background-size: 101%;
			background-size: cover;
			width: 100%;
			// height: 100%;
			// min-height: 250px;
			height: 250px;
		}
	}
}

.todays-front-page {
	.preview {
		height: 300px;
		padding: 1.25rem 4rem 0 2rem;
		overflow: hidden;
		// border-bottom: 3px solid $columbian-light;

		@include media-breakpoint-only(lg) {
			height: 174px;
		}
		@include media-breakpoint-only(sm) {
			height: 500px;
			padding-left: 4rem;
		}

		img {
			transform: rotate(10deg);
			box-shadow: 10px 10px 30px rgba( $black, 0.15 );
		}
	}
}

.special-projects {
	.see-also {
		.title {
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 6px;
		}
		.story-group {
			font-family: $font-family-headline;
			font-size: 18px;
			line-height: 24px;

			a {
				color: $white;
			}
		}
	}
}

.wp-polls {
	font-family: $font-family-sans-serif; // needed if a poll is placed in an article with a shortcode instead of a media embed

	input.btn:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
	.progress {
		padding: 2px;
		background-color: $gray-400;

		.progress-bar {
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}
	}
	.wp-polls-loading {
		margin-top: 1rem;

		img {
			margin-right: 0.2rem;
		}
	}
}

.columnists {
	.mugshot {
		// border-right: 3px solid rgba( $columbian, 0.5 );
		// border-bottom: 3px solid rgba( $columbian, 0.5 );
		border-right: 3px solid $columbian-faded;
		border-bottom: 3px solid $columbian-faded;
		width: 75%;
		transition: 0.2s ease;
	}
	a:hover .mugshot {
		box-shadow: 2px 0px 0 3px rgba( $columbian-faded, 0.2 );
	}
}
.home .subscribe-cta {
	padding-top: 2rem;
	padding-bottom: 2rem;

	p {
		font-family: $font-family-headline;
		font-size: 1.05rem;
		line-height: 24px;
	}
}

#google_ads_iframe_\/2444258\/v3_homepage_fixed_bottom_0__container__ + a {
	font-size: 0 !important;
	padding: 0 !important;
	height: 0;

	&:before {
		content: '';
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="%23fff" width="12" height="12" viewBox="0 0 16 16"><line x1="2.5" y1="13.5" x2="13.5" y2="2.5" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.25"/><line x1="13.5" y1="13.5" x2="2.5" y2="2.5" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.25"/></svg>');
		width: 12px;
		height: 12px;
		display: block;
		background-color: $secondary;
		background-repeat: no-repeat;
		background-position: center;
		padding: 10px;
		border-radius: 100%;
		transition: background-color 0.2s ease;
		margin-top: -0.1rem;
		margin-right: -0.55rem;
	}
	&:hover:before {
		background-color: darken($danger, 7.5%);
	}
}

@include media-breakpoint-only(md) {
	.special-2col-bg {
		background-color: $light;
		padding: 1.5rem 2rem;

		.excerpt {
			margin-bottom: 0;
		}
	}
}

@include media-breakpoint-down(md) {
	.top-stories {
		.large-feature {
			padding-top: 6rem;
		}
	}
}

@include media-breakpoint-down(sm) {
	.excerpt {
		display: none !important;
	}
}
