// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $font-family-subhead;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
    cursor: not-allowed;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// custom style overrides
.btn-columbian:focus,
.btn-columbian-faded, .btn-columbian-light, .btn-columbian-light:hover,
.btn-columbian-faded.disabled, .btn-columbian-faded:disabled,
.btn-columbian-light.disabled, .btn-columbian-light:disabled,
.btn-columbian-light:not(:disabled):not(.disabled):active {
  color: $white !important;
}

/* for using with dashicons
.btn-arrow:after{
  content: "\f345";
  display: inline-block;
  width: 15px;
  height: 15px;
  font-size: 15px;
  line-height: 1;
  font-family: dashicons;
  text-decoration: inherit;
  font-weight: 400;
  font-style: normal;
  margin-left: 0.25rem;
  // vertical-align: top;
  text-align: center;
  transition: color .1s ease-in 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-sm.btn-arrow:after{
  width: 10px;
  height: 10px;
  font-size: 10px;
}
.btn-lg.btn-arrow:after{
  width: 20px;
  height: 20px;
  font-size: 20px;
} */
.btn-arrow {
  &:after {
    content: '';
    display: inline-block;
    background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right-white.svg);
    background-repeat: no-repeat;
    background-position: center right;
    width: 7px;
    height: 10px;
    background-size: 7px 10px;
    padding-left: 14px;
  }
  &.btn-sm:after {
    width: 6px;
    height: 10px;
    background-size: 6px 8px;
    padding-left: 13px;
  }
  &.btn-lg:after {
    width: 10px;
    height: 15px;
    padding-left: 18px;
    background-size: 10px 13px;
  }
}
// use black arrow for light-colored buttons
.btn-light, .btn-warning {
  &.btn-arrow {
    &:after {
      background-image: url(/wp-content/themes/columbian2/static/img/icons/chevron-right.svg);
    }
  }
}
.btn-pdf:before {
  content: '';
  display: inline-block;
  line-height: 0;
  vertical-align: -6px;
  height: 22px;
  width: 19px;
  margin-right: 0.5rem;
  background-image: url(/wp-content/themes/columbian2/static/img/icons/pdf-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  font-family: $font-family-headline;
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  font-family: $font-family-sans-serif;
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
  line-height: 1.3;
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

