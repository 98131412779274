// stylelint-disable declaration-no-important

//
// Border
//

.border         { border: $border-width solid $border-color !important; }
.border-top     { border-top: $border-width solid $border-color !important; }
.border-right   { border-right: $border-width solid $border-color !important; }
.border-bottom  { border-bottom: $border-width solid $border-color !important; }
.border-left    { border-left: $border-width solid $border-color !important; }

.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}
.border-light { // override to bootstrap original $gray-100
  border-color: $light-bootstrap-default !important;
}

// for giving a standard thicker border
.border-thick {
  border-width: 3px !important;
}

//
// Border-radius
//

.rounded {
  border-radius: $border-radius !important;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

// added for better matching with form-control-lg
.form-control-lg.rounded {
  border-radius: $border-radius * 2 !important;
}
.form-control-lg.rounded-top {
  border-top-left-radius: $border-radius * 2 !important;
  border-top-right-radius: $border-radius * 2 !important;
}
.form-control-lg.rounded-right {
  border-top-right-radius: $border-radius * 2 !important;
  border-bottom-right-radius: $border-radius * 2 !important;
}
.form-control-lg.rounded-bottom {
  border-bottom-right-radius: $border-radius * 2 !important;
  border-bottom-left-radius: $border-radius * 2 !important;
}
.form-control-lg.rounded-left {
  border-top-left-radius: $border-radius * 2 !important;
  border-bottom-left-radius: $border-radius * 2 !important;
}


// non-standard bootstrap responsive borders
// from https://github.com/twbs/bootstrap/issues/23892#issuecomment-378172751

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top    { border-top: $border-width solid $border-color; }
    .border#{$infix}-right  { border-right: $border-width solid $border-color; }
    .border#{$infix}-bottom { border-bottom: $border-width solid $border-color; }
    .border#{$infix}-left   { border-left: $border-width solid $border-color; }

    .border#{$infix}-top-0    { border-top: 0; }
    .border#{$infix}-right-0  { border-right: 0; }
    .border#{$infix}-bottom-0 { border-bottom: 0; }
    .border#{$infix}-left-0   { border-left: 0; }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color;
      border-right: $border-width solid $border-color;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color;
      border-bottom: $border-width solid $border-color;
    }
  }
}
