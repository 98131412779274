// video page
iframe.video-player {
	@include media-breakpoint-only(md) {
		height: 388px;
	}
	@include media-breakpoint-down(sm) {
		height: 287px;
	}
}


// death notices and vital statistics
.death-notices-list, .vitals-list {
	font-family: $font-family-subhead;
	font-size: 1.1rem; // for better readability in serif font
	line-height: 2;
}
.vitals-list span {
	&:not(:first-child) {
		text-transform: lowercase;
	}
	&:after {
		content: ', ';
	}
	&:last-child:after {
		content: '';
	}
	&:last-child:not(:first-child):not(:nth-child(2)):before {
		content: 'and ';
	}
}


// tweet group
.bg-twitter {
	background-image: url('/wp-content/themes/columbian2/static/img/icons/social/twitter.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 14% 1.5rem;
	background-blend-mode: luminosity;

	// if another feature is right below the tweets that happens to have the same background color (columbian-faded)
	& + .bg-columbian-faded {
		background-color: $columbian !important;

		.badge-columbian {
			background-color: $columbian-faded !important;
		}
	}
}



.archive {
	@include media-breakpoint-up(md) {
		h1 {
			font-size: 3.5rem;
		}
	}

	// for making the thumbnails all equal, even when there is content floating to the right of it
	.story {
		.thumb {
			max-width: 210px;
			flex-basis: 210px;
			padding: 0 15px;
		}
		.details {
			flex-basis: 0;
			flex-grow: 1;
			padding: 0 15px;

			.mobile-thumb {
			    width: 35%;
				max-width: 180px;
			}
		}
		img.has-video-icon {
			vertical-align: 0.25rem !important;
			margin-left: 0.6rem !important;
		}
	}
}


.hero {
	background-image:
		linear-gradient(to bottom right, rgba($columbian, 0.75), rgba($columbian-faded, 0.5)),
		url(/wp-content/themes/columbian2/static/img/hero/i-5-bridge-wa-or-border-columbia-river.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-blend-mode: exclusion;
	text-shadow: 0rem .25rem 1rem $black;

	h1 {
		text-shadow: 0rem .5rem 1.5rem $black;
	}
}

.cross-column {
	@include media-breakpoint-up(md) {
		column-count: 3;
		column-gap: 30px;
	}
}


.pink-container {
	background: #fbdce4;
	background: -moz-radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
	background: -webkit-radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
	background: radial-gradient(circle, #fbdce4 50%, #e6c1ca 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbdce4",endColorstr="#e6c1ca",GradientType=1);

	h1, .h1, .small, a {
		color: #af4975;
	}
	h3.title-lined {
		border-color: #e6c1ca;

		@include media-breakpoint-up(md) {
			span {
				background-color: #fbdce4;
			}
		}
	}
	.btn-light {
		background-color: rgba(#fff1f5, 0.8);
		border-color: rgba(#fff1f5, 0.8);

		&:hover {
			background-color: #fdd4de;
			border-color: #ffe3eb;
			color: #862c53;
		}
		&.btn-arrow:after {
			background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='20' viewBox='434 310 14 20'%3E%3Cpath fill='%23d67ca3' d='M437 310l-3 3 8 7-8 7 3 3 11-10-11-10z'/%3E%3C/svg%3E"); /* right arrow with custom matching color */
		}
	}
}


// search page
label#authors_label, #authors_chosen {
	transition: opacity 0.2s ease;
}
.btn .spinner-border {
	width: 1.5rem;
	height: 1.5rem;
	border-width: 0.15em;
	vertical-align: middle;
}

// pages with numbered outlines
body.page ol li {
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}


// contact form 7 customizations
.glyphicon-asterisk:before {
	content: '*';
	font-size: 2rem;
	float: right;
	line-height: 2.5rem;
	color: #f00;
	margin-left: 0.3rem;
	line-height: 0;
	margin-top: 1rem;
}
span.wpcf7-not-valid-tip {
	font-size: 90%;
	font-weight: bold;
	margin-top: 0.25rem;
	margin-bottom: 1rem;
	color: $red;
}
span.wpcf7-list-item {
	margin: 0 0 0 2rem;

	&.first {
		margin-left: 0;
	}
	input[type=radio] {
		margin-right: 0.4rem;
	}
}
// override response message styles defined by plugin to match bootstrap alerts
.wpcf7 {
	form {
		.wpcf7-response-output {
			padding: $alert-padding-y $alert-padding-x;
			margin: 1.5rem 0 1rem;
			border: $alert-border-width solid transparent;
			@include border-radius($alert-border-radius);

			&:before {
				margin-right: 0.5rem;
			}
		}
		&.invalid, &.unaccepted, &.failed, &.aborted, &.spam {
			.wpcf7-response-output {
				color: theme-color-level("danger", $alert-color-level);
				@include gradient-bg( theme-color-level("danger", $alert-bg-level) );
				border-color: theme-color-level("danger", $alert-border-level);

				&:before {
					content: '';
					display: inline-block;
					background-image: url('/wp-content/themes/columbian2/static/img/icons/danger.svg');
					background-repeat: no-repeat;
					width: 22px;
					height: 22px;
					background-size: 22px;
					vertical-align: -5px;
				}
			}
		}
		&.sent {
			.wpcf7-response-output {
				color: theme-color-level("success", $alert-color-level);
				@include gradient-bg( theme-color-level( "success", $alert-bg-level ) );
				border-color: theme-color-level("success", $alert-border-level);

				&:before {
					content: '';
					display: inline-block;
					background-image: url('/wp-content/themes/columbian2/static/img/icons/success.svg');
					background-repeat: no-repeat;
					width: 18px;
					height: 18px;
					background-size: 18px;
					vertical-align: -6px;
				}
			}
		}
	}
}
